import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { CreateUserComponent } from '../create-user/create-user.component';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { UserService } from '../user.service';
import Swal from 'sweetalert2';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { UploaduserComponent } from '../uploaduser/uploaduser.component';
import { HttpClient } from '@angular/common/http';
import { FamilyCurrentlocationComponent} from 'src/app/Components/family-currentlocation/family-currentlocation.component';
// import { UserLocationComponent  } from '../user-location/user-location.component';
// import {} from '../';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber', 'userType', 'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  @ViewChild(MatPaginator) paginator:any;
 
  userlist :any = [];
  isLoading: boolean = false
  csvRecords: any[] = [];
  header = true;
  final_json :any = [];
  btn: boolean=false;
  constructor(public dialog: MatDialog, private gs: UserService, private Gs: GlobalserviceService, private auth: AuthService,private ngxCsvParser: NgxCsvParser ,  private _httpClient: HttpClient,
   ) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    console.log(this.gs.userList)
    // this.dataSource = this.gs.userList
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    console.log(userdata.showMyLive);
    console.log()
    // if(Object.keys(userdata.organziation).length>0)
    // if(Object.keys(userdata.organization).length === 0&&userdata.showMyLive==true) 
    if(userdata.organization.organizationId==null&&userdata.showMyLive==true) 
    {
      console.log("Object is empty");
      this.btn=true;
    } 
    else 
    { 
      console.log("Object is not empty");
      this.btn=false;
    }  
    // if(userdata.organization!=null||userdata.organization!=undefined)
    // {
    //    console.log("value Present");
    //    this.btn=false;
    // }
    // else
    // {
    //   console.log("value Not Present");
    //   this.btn=true;
    // }   
    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true
      let id = this.auth.getLocal_Org().organizationId
      console.log(id)
      this.Gs.get_user_org(id).subscribe((a: any) => {

        a.map(
          (e: any)=>{
              if(userdata.userType[0].userTypeName == "BUDDY USER")
              {
                
               if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
                 console.log(e)
                  this.userlist.push(e);
               }
              }else {
               if(e.userId !=  userdata.userId  ){
                 console.log(e)
                  this.userlist.push(e);
               }
              }
            }
       )
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a)

      })
    } else {
      let id = this.auth.getLocalStorageData()?.userId;
      this.Gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
           if(e.userId != id){
           //  console.log(e)
              this.userlist.push(e);
           }
         }
       )
        this.dataSource = new MatTableDataSource(this.userlist);
        this.dataSource.paginator = this.paginator;
        console.log(a)
      })
    }
  }

  viewLocation()
  {
       console.log("last user Location");
       const dialogRef = this.dialog.open(FamilyCurrentlocationComponent, {
        width: '800px',
        height:'500px',
        });
  }
  openCreateUserDialog() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      disableClose: true,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (this.auth.getLocal_Org()?.organizationId) {
        let id = this.auth.getLocal_Org().organizationId
        console.log(id)
        this.Gs.get_user_org(id).subscribe((a: any) => {
          this.dataSource = new MatTableDataSource(a);
          console.log(a)

        })
      } else {
        let id = this.auth.getLocalStorageData()?.userId;
        this.Gs.get_primary_users_user(id).subscribe((a: any) => {
          this.dataSource = new MatTableDataSource(a);
          console.log(a)
        })
      }

      this.dataSource.paginator = this.paginator;
    });
  }


     UploadUserDialog() {
    const dialogRef = this.dialog.open(UploaduserComponent, {
      disableClose: true,
      width: '400px',
    });
       
    this.userlist.length =0;
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      let  userdata = this.auth.getLocalStorageData();
      if (this.auth.getLocal_Org()?.organizationId) {
        this.isOrg = true
        let id = this.auth.getLocal_Org().organizationId
        console.log(id)
        this.Gs.get_user_org(id).subscribe((a: any) => {
  
          a.map(
            (e: any)=>{
                if(userdata.userType[0].userTypeName == "BUDDY USER")
                {
                  
                 if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
                   console.log(e)
                    this.userlist.push(e);
                 }
                }else {
                 if(e.userId !=  userdata.userId  ){
                   console.log(e)
                    this.userlist.push(e);
                 }
                }
           
           }
         )
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          console.log(a)
  
        })
      } else {
        let id = this.auth.getLocalStorageData()?.userId;
        this.Gs.get_primary_users_user(id).subscribe((a: any) => {
          a.map(
            (e: any)=>{
             if(e.userId != id){
             //  console.log(e)
                this.userlist.push(e);
             }
           }
         )
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          console.log(a)
        })
      }
      this.dataSource.paginator = this.paginator;
    });
  }

 
  editUser(a: any) {

    console.log(a)

    const dialogRef = this.dialog.open(EditUserComponent, {
      disableClose: true,

      
      data : a,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {

         this.userlist.length = 0;
      let  userdata = this.auth.getLocalStorageData();

      if (this.auth.getLocal_Org()?.organizationId) {
        this.isOrg = true
        let id = this.auth.getLocal_Org().organizationId
        console.log(id)
        this.Gs.get_user_org(id).subscribe((a: any) => {
  
          a.map(
            (e: any)=>{
                if(userdata.userType[0].userTypeName == "BUDDY USER")
                {
                 if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
                   console.log(e)
                    this.userlist.push(e);
                 }
                }else {
                 if(e.userId !=  userdata.userId  ){
                   console.log(e)
                    this.userlist.push(e);
                 }
                }
           
           }
         )
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          console.log(a)
  
        })
      } else {
        let id = this.auth.getLocalStorageData()?.userId;
        this.Gs.get_primary_users_user(id).subscribe((a: any) => {
          a.map(
            (e: any)=>{
             if(e.userId != id){
             //  console.log(e)
                this.userlist.push(e);
             }
           }
         )
          this.dataSource = new MatTableDataSource(this.userlist);
          this.dataSource.paginator = this.paginator;
          console.log(a)
        })
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // downloadsample()
  // {
  //   // this._httpClient.get(`ssets\template\UsersTemplate`, {
  //   //   observe: 'response',
  //   //   responseType: 'blob'
  //   // }).subscribe(res => {
  //   //   this._FileSaverService.save();
  //   // });
  // }
}
