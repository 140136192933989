import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }


  userList: any[] = [
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    },
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    }
    , {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    },
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    },
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    },
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    }
    , {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    },
    {
      "userId": 9830,
      "name": "mani",
      "phoneNumber": "7845625784",
      "email": "mk@gmail.com",
      "userType": [],
      "attachment": {
        "attachmentId": 9832,
        "fileDownloadUrl": "http://192.168.1.248:6008/trackingsupervisor/download_image/9832",
        "fileViewUrl": "http://192.168.1.248:6008/trackingsupervisor/view_image/9832",
        "attachmentByte": {
          "attachmentByteId": 406
        }
      },
      "organization": {
        "organizationId": 9826,
        "organizationName": "organization"
      }
    }

  ]
}
