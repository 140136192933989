import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  ForgetPasswordForm!: FormGroup
  ForgetPasswordCodeForm! : FormGroup
  hide = true;
  loading = false;
  emailtemp= true;
  forgettemp = false;
  emaildata : any = {};
  constructor(private fb: FormBuilder , private gs : GlobalserviceService , private router: Router) { }

  ngOnInit(): void {

    this.ForgetPasswordForm = this.fb.group({
     
           email: ['',[ Validators.required , Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]]   
    });
     
    this.ForgetPasswordCodeForm = this.fb.group({
     
      forgetpasswordcode: ['',[ Validators.required ]]   
});
 
  }
  get f() {
    return this.ForgetPasswordForm.controls;
  }

  get ff() {
    return this.ForgetPasswordCodeForm.controls;
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  forgetpassword()
  {

    if (this.ForgetPasswordForm.invalid) {
      return;
    }
    this.loading = true
    let x = this.ForgetPasswordForm.value;
    this.emaildata = this.ForgetPasswordForm.value;
    console.log(x)
    
    this.gs.forgetpassword(x).subscribe(
      (d: any) => {
        this.loading = false
        this.emailtemp = false;
        this.forgettemp = true;
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
      //this.loading = true;
   

  }

  forgetpasswordlogin()
  {
    if (this.ForgetPasswordCodeForm.invalid) {
      return;
    }
    this.loading = true
    let x = this.ForgetPasswordCodeForm.value;
         
    console.log(x)
    
   let forgetpasswordcodedata  = ​{
      email: this.emaildata.email,
      otp:x.forgetpasswordcode
      }​
      
      
          console.log(forgetpasswordcodedata);

          

    this.gs.forgetpasswordcode(forgetpasswordcodedata).subscribe(
      (d: any) => {
        this.loading = false
        if (d.userType[0].userTypeId == 3) {
          Swal.fire({
            text: 'Access Denied',
            icon: 'info',
          })

          return
        }


        localStorage.setItem('PrintLok$', JSON.stringify(d));
        if (d) {
          Swal.fire({
            text: 'You Have Logged In Successfully',
            icon: 'success',
          })
        }
        this.router.navigate(['/']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.description
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.description)
          if (err) {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }
}
