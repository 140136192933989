<div class="login_container">

    <div class="col-md-5">
      <!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
        {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
      </button> -->
  
      <ng-container *ngIf="loading; else elseComponent">
        <div class="loader">
          <app-loading></app-loading>
          <br>
          <h2>Please Wait...!</h2>
        </div>
      </ng-container>
      <ng-template #elseComponent >
        <div class="brand">
            <img src="/assets/jepericon.png" alt="" height="75px" />
            <h4>Forget Password</h4>
          </div>
           <div *ngIf="emailtemp">
            <form action="" [formGroup]="ForgetPasswordForm" (ngSubmit)="forgetpassword()">
           

                <!-- org mail id -->
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>EMail-Id</mat-label>
                  <mat-icon style="
                            transform: translateY(0px);" matPrefix>email</mat-icon>
                  <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
                    [errorStateMatcher]="matcher" />
                  <mat-error style="color: rgb(0, 0, 0);"
                    *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.email.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
  
            
                <div align="center">
                  <!-- <button mat-stroked-button color="primary" style="width: 30%; margin: 20px 0 0 8px;" mat-button
                  type="button" routerLink="/login">Cancel</button> -->
                  <button mat-stroked-button style="width: 40%; margin: 20px 10px 0 0px;" mat-button
                    type="submit">Ok</button>
  
                    <button mat-stroked-button style="width: 40%; margin: 20px 10px 0 0px;" mat-button
                    type="button"  [routerLink]="['/']" >Cancel</button>
                </div>
               
               
            
              
             
            </form>
           </div>
           <div *ngIf="forgettemp">

             
            <form action="" [formGroup]="ForgetPasswordCodeForm" (ngSubmit)="forgetpasswordlogin()">
           

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Code</mat-label>
                  <mat-icon style="transform: translateY(0px);" matPrefix>pin</mat-icon>
                  
                  <input matInput type="text" placeholder="Code" maxlength="10" (keypress)="noNumber($event)"
                    style="padding: 0px 0 0 33px ;" [disabled]='true' formControlName="forgetpasswordcode"
                    [errorStateMatcher]="matcher" />
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.forgetpasswordcode.hasError('required')">Please Enter
                    <strong>Code</strong>
                  </mat-error>
    
                </mat-form-field>
  
            
                <div align="center">
                  <!-- <button mat-stroked-button color="primary" style="width: 30%; margin: 20px 0 0 8px;" mat-button
                  type="button" routerLink="/login">Cancel</button> -->
                  <button mat-stroked-button style="width: 40%; margin: 20px 10px 0 0px;" mat-button
                    type="submit">Ok</button>
  
                    <button mat-stroked-button style="width: 40%; margin: 20px 10px 0 0px;" mat-button
                    type="button" [routerLink]="['/']">Cancel</button>
                </div>
               
               
            
              
             
            </form>
           </div>
       
      </ng-template>
    </div>
  
  </div>
  