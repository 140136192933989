import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/Components/Location/assign-location/assign-location.component';
import Swal from 'sweetalert2';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  hide = true;
  email: boolean = true;
  /* form */
  loginForm!: FormGroup;

  loading = false;
  submitted = false;
  id: any

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.email) {
      this.loginForm = this.formBuilder.group({
        OTP: ['', [Validators.required]],
      });
    } else {

    }

    this.route.paramMap.subscribe(a => {
      let x = a.get('id')
      this.id = x
      console.log(x)
    })
  }

  get f() { return this.loginForm.controls; }

  change_field() {

    this.email = !this.email
    console.log(this.email)
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true
    let x = this.loginForm.value;
    console.log(x)
    this.authService.verifyEmail(this.f.OTP.value, this.id).subscribe(
      (d: any) => {
        this.loading = false
        if (d) {
          Swal.fire({
            text: d.description,
            icon: 'success',
          })
        }
        this.router.navigate(['/']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.description
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.description)
          if (err) {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }
}
