
  <div class="container">
    <h2 mat-dialog-title>Create Freetrial</h2>
    <form action="" [formGroup]="userForm">
      <!-- name -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>noOfDays</mat-label>
        <mat-icon matPrefix style="color:#6d6b6b;">date_range</mat-icon>
        <input matInput type="text" formControlName="noOfDays" maxlength="3" (keypress)="omit_special_char($event) && keyPressnumber($event)" >  
        <mat-error *ngIf="f.noOfDays.hasError('required')">
          Please enter <strong>noOfDays</strong>
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="full-width">
        <mat-label>FreeTrialName</mat-label>
        <mat-icon matPrefix style="color:#6d6b6b;">supervisor_account</mat-icon>
        <input matInput type="text" formControlName="freeTrialName"  (keypress)="omit_special_char($event) && keyPress($event)" >
        <mat-error *ngIf="f.freeTrialName.hasError('required')">
          Please enter <strong>name</strong>
        </mat-error>
      </mat-form-field> 

  
      <section class="example-section" >
        <mat-checkbox class="example-margin" formControlName="default">Default</mat-checkbox>
      </section>
      <div class="form-group" align="center">
        <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button" mat-button
          mat-dialog-close>CANCEL</button>
        <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit"
          [disabled]="userForm.invalid" (click)="create_user()">
          {{data ? 'UPDATE' :'CREATE'}}
        </button>
      </div>
    </form>
  </div>