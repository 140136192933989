<ng-container *ngIf="isLoading; else elseTemplate">
    <div class="loading">
      <app-loading></app-loading>
      <br>
      <h1>Please Wait Loading...</h1>
    </div>
  </ng-container>
  
  <ng-template #elseTemplate>
    <div class="container">
  
      <div class="mat-elevation-z8">
    
        <div class="header_action">
         
     
         
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="search name/ email/ phone number" #input>
          </mat-form-field>
        </div>
        <!-- <div class="filter">
    
        </div> -->
        <table mat-table [dataSource]="dataSource">
    
          <!-- Position Column -->
          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
            <td mat-cell *matCellDef="let element; let i =index">  {{ currentPageOffset + i + 1 }} </td>
          </ng-container>
    
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> <b>NAME</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
    
    
    
          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> <b>EMAIL ID</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> <b>PHONE NUMBER</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="userType">
            <th mat-header-cell *matHeaderCellDef> <b>USER TYPE</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.userType[0].userTypeName}} </td>
          </ng-container>
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Example icon-button with a menu" (click)="trackUser(element)" >
                <mat-icon>map</mat-icon>
              </button>
              <!-- <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <mat-icon>dialpad</mat-icon>
                  <span>Redial</span>
                </button>
                <button mat-menu-item disabled>
                  <mat-icon>voicemail</mat-icon>
                  <span>Check voice mail</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>notifications_off</mat-icon>
                  <span>Disable alerts</span>
                </button>
              </mat-menu> -->
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" align="center">
              <div class="alert alert-secondary" style="width: 155%;">
                <h3>No User List Found {{input.value}}</h3>
              </div>
            <td>
          </tr>
        </table>
    
    
        <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
    
      </div>
    </div>
    </ng-template>
  
  