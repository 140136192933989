<!-- <ng-container *ngIf="loading">
    <div class="loader">
        <app-loading></app-loading>
    </div>
 </ng-container> -->
  <mat-card  style="width: 90%; margin: auto;">
      <br>
      <mat-card-title>
        Add Geofence
      </mat-card-title>
      <br>
      <form action="" [formGroup]="geofenceForm" (ngSubmit)="GeofenceSubmit()">
          <div>
            <div class="form-group">
                <label>Geofence Name <span style="color:red"> *</span></label>
                <input type="text"  id="geofencename"  formControlName="geofencename" class="form-control"  (keydown.enter)="$event.preventDefault()" placeholder="Enter GeofenceName"
                    autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                    <div *ngIf="(geofence.geofencename.invalid && geofence.geofencename.touched) || geofence.geofencename.dirty || submitted">
                        <div *ngIf="geofence.geofencename.errors?.required" class="text-danger">*Geofence name is required.</div>
                    </div>  
            </div>
            <div>
                <label>Geofence Points<span style="color:red"> *</span></label>
                <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing">
                </agm-map> -->
                <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing" (mapReady)="onMapReady($event)">
                </agm-map> -->
                <div *ngIf="geofenaceId; else elseBlock">
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  (mapReady)="onMapReady($event)">
                    </agm-map>
                </div>
                <ng-template #elseBlock>
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" formControlName="geofencelocation"  [agmDrawingManager]="drawing">
                    </agm-map>
                </ng-template>

                <agm-drawing-manager #drawing="agmDrawingManager" (polygonComplete)="polygonCreated($event)" [drawingControlOptions]="managerOptions.drawingControlOptions"
                    [polygonOptions]='managerOptions.polygonOptions' [drawingMode]='managerOptions.drawingMode'></agm-drawing-manager>
                    <div *ngIf="geofenceLoc" class="text-danger">
                        *Geofence points is required.
                    </div>
                <!-- <div *ngIf="(geofence.geofencelocation.invalid && geofence.geofencelocation.touched) || geofence.geofencelocation.dirty || submitted">
                    <div *ngIf="geofence.geofencelocation.errors?.required" class="text-danger">*Geofence points is required.</div>
                </div>   -->
            </div>
          </div>
          <div>
            <button  mat-stroked-button color="warn" style="width: 100%; margin: 20px 0 0 8px;" type="submit" mat-button>{{geofenaceId ? "Update Geofence":"Create Geofence"}}</button>
          </div>
      </form>
  </mat-card>