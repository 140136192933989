<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading">
    <app-loading></app-loading>
    <br>
    <h1>Please Wait Loading...</h1>
  </div>
</ng-container>

<ng-template #elseTemplate>
  <div class="container">

    <div class="mat-elevation-z8">
  
      <div class="header_action">
       
        <!-- <button mat-flat-button>
        <span class="sign_up"  [routerLink]="['/add-user']"> <mat-icon matPrefix style="
          transform: translateY(8px);">add</mat-icon>
          &nbsp; Create User 
        </span>
        </button> -->
           <button mat-flat-button (click)="CreateUserDialog()">
        <span class="material-icons">
          add
        </span>
        <span>Create FreeTrial</span>
      </button>
        <!-- <mat-menu #menu="matMenu"  yPosition="above" hasBackdrop="true">
     
          <button mat-menu-item [routerLink]="['/add-user']">User</button>             
        </mat-menu> -->

   
       
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="search  noOfDays/ email/ phone number" #input>
        </mat-form-field>
      </div>
      <!-- <div class="filter">
  
      </div> -->
      <table mat-table [dataSource]="dataSource">
  
        <!-- Position Column -->
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
          <td mat-cell *matCellDef="let element; let i =index"> {{i+1}} </td>
        </ng-container>
        
  
        <!-- Name Column -->
        <ng-container matColumnDef="NO-OFDAYS">
          <th mat-header-cell *matHeaderCellDef> <b>NO OFDAYS</b> </th>
          <td mat-cell *matCellDef="let element"> {{element.noOfDays}} </td>
        </ng-container>

  
      

   <!-- Symbol Column -->
      <ng-container matColumnDef="freeTrialName">
        <th mat-header-cell *matHeaderCellDef> <b>FREETRIAL</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.freeTrialName}}</td>
      </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="default">
          <th mat-header-cell *matHeaderCellDef> <b>DEFAULT</b> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="Example icon-button with a menu"  >
              <!-- <mat-icon class="success" *ngIf="element.default == true" 
              [ngStyle]="{'color': element.active == false ? 'red' : 'green'}"
              (click)="toggleUpdate(element)">
              {{element.default == false ? 'toggle_off' : 'toggle_on'}}
              </mat-icon> -->
              <mat-icon class="success" *ngIf="element.default == true" >toggle_on
              </mat-icon>
              <mat-icon class="danger" *ngIf="element.default == false" 
              (click)="toggleUpdate(element)">toggle_off
              </mat-icon>
           
            </button>
          </td>
        </ng-container>

         <!-- Weight Column -->
       <ng-container matColumnDef="STATUS">
        <th mat-header-cell *matHeaderCellDef> <b>STATUS</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.status.statusName}}</td>
      </ng-container> 
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
          <td mat-cell *matCellDef="let element,let i = index">
            <button mat-icon-button aria-label="Example icon-button with a menu" (click)="editUser(element,i)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" align="center">
            <div class="alert alert-secondary" style="width: 155%;">
              <h3>No User List Found {{input.value}}</h3>
            </div>
          <td>
        </tr>
      </table>
  
  
   
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  
    </div>
  </div>
  </ng-template>

