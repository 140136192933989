
<div class="row remove">
  <mat-icon class="material-icons-outlined" (click)="remove()">
    highlight_off
  </mat-icon >
</div>

<agm-map   [latitude]="latitude" [longitude]="longitude"  [zoom]="18">
    <agm-marker  *ngFor="let report of reportList; let i = index"  [latitude]="report.latitude" [longitude]="report.longitude" 
    (mouseOver)="onMouseOver(infoWindow, $event)" 
    (mouseOut)="onMouseOut(infoWindow, $event)"
      [markerDraggable]="true">
        <agm-info-window [disableAutoPan]="false" #infoWindow>
            <div>
                 <b style="color:red">{{report.date | date: 'dd/MM/yyyy'}}</b><br>
                 <!-- <span>{{report.time}}</span><br> -->
                 <span>{{report.address}}</span>
            </div>
       </agm-info-window>
    </agm-marker>
    <agm-polyline [strokeColor]="'#800020'" [strokeWeight]=2>
      <agm-polyline-point *ngFor="let line of reportList; let i = index" [latitude]="line.latitude" [longitude]="line.longitude">
      </agm-polyline-point>
    </agm-polyline>
    <agm-polygon [paths]="points" [strokeColor]="'red'" [fillColor]="'#90EE90'" [strokeWeight]="1"></agm-polygon>
</agm-map>
<!-- <agm-drawing-manager #drawing="agmDrawingManager"  [drawingControlOptions]="managerOptions.drawingControlOptions"
[polygonOptions]='managerOptions.polygonOptions' [drawingMode]='managerOptions.drawingMode'></agm-drawing-manager> -->
