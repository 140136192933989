
<div class="row">
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-12"><span class="material-icons" >
        perm_identity
        </span>
        <span class="font">&nbsp; &nbsp;{{data.name}}</span>
      </div>
      <div class="col-sm-12"> <span class="material-icons" >
        call
        </span>
        <span class="font">&nbsp; &nbsp;{{data.phoneNumber}}</span>
      </div>
    </div>
    </div>
    <div class="col-sm-6">
  <span class="material-icons"   (click)="closedialog()" mat-dialog-close style="transform: translateY(0px);float: right;color:red">cancel</span>
    </div>
</div>

<br>
<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
 (mapReady)="onMapReady($event)" (mapClick)="mapClicked($event)">
</agm-map>