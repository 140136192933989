
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  @ViewChild('search') searchElementRef!: ElementRef;
  myModel = ''
  public mask = [/[0-9]/, /\d/, '-', /\d/, /\d/]
  
  AddLocationForm !: FormGroup
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  geocodedaddress :any;
  isLoading :any;
  map:any;
  Marker:any;
  loading:any= false;

  constructor(private fb: FormBuilder , private auth : AuthService , private gs : GlobalserviceService ,private router: Router) { }

  ngOnInit(): void {
       this.isLoading = false;
    this.AddLocationForm = this.fb.group({
      AddressName :  ['', [Validators.required ]],
      address: this.fb.group(
        {
          addressName: [''],
          latitude: ['', Validators.required],
          longitude: ['', Validators.required],
        }
      ),
      status: this.fb.group({
        statusId: 1
      })
    })

    this.setCurrentLocation();
   // this.addLocation();
    
  }

  get f() {
    return this.AddLocationForm.controls;
  }


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }


  onMapReady(map: any) {
    alert("map ready");
    console.log(map);
    this.map = map;
   
       

  }

  getaddress()
  {
        let address = document.getElementById("address");

        const options = {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
          types: ["establishment"],
        };
      
       // map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
     const autocomplete = new google.maps.places.Autocomplete(address);
   //     const autocomplete = autocompleteapi(address, options);
     //   console.log(autocomplete);

        autocomplete.addListener("place_changed", ()=>{
               this.loading = true;
            let place = google.maps.places.PlaceResult = autocomplete.getPlace();

            if(place.geometry === undefined || place.geometry === null) {
              return;
            }
            let lat = place.geometry.location.lat();
          let lng = place.geometry.location.lng();

        // const mark = new google.maps.LatLng(lat,lng);

          this.latitude = lat
          this.longitude = lng
          this.loading = false;
        console.log(lat,lng);
        })
  }


  addLocation() {
    console.log(this.AddLocationForm.value)
    let x = this.AddLocationForm.value
       

    if (this.AddLocationForm.invalid) {
      return
    }
   
    // alert("form submitted");
     
     let user = this.auth.getLocalStorageData()
     let locationdata;
        console.log(user);

          if(user.organization.organizationId != null 
            || user.organization.organizationId != undefined)
            {
               locationdata = {

                latitude : x.address.latitude,
                longitude: x.address.longitude,
                address: x.AddressName, 
                user:{
                  userId: user.userId
                } ,
                organization : {
                  organizationId : user.organization.organizationId
                }
                 
              };
            }else {
               locationdata = {

                latitude : x.address.latitude,
                longitude: x.address.longitude,
                address: x.AddressName, 
                user:{
                  userId: user.userId
                } 
              };
            }
         

          console.log("locationdata");
          console.log(locationdata);

          this.isLoading = true
          this.gs.Create_Location(locationdata).subscribe((a: any) => {
            
            
            Swal.fire(
              'Location Created Successfully',
              "",
              'success'
            )
            this.isLoading = false
            this.router.navigate(['/location-list']);
            console.log(a)
          },
          err => {
            console.log
            this.isLoading = false
         
            Swal.fire(
              err.error.message,
              "",
              'error'
            )
          })

  }

  markerDragEnd(a: any) {
    console.log(a.coords)
    this.latitude = a.coords.lat
    this.longitude = a.coords.lng

    let geocoder = new google.maps.Geocoder;
    let latlng = {lat: this.latitude, lng: this.longitude};
    let that = this;
  

      geocoder
    .geocode({ location: latlng })
    .then((response :any) => {

      console.log(response.results[0].formatted_address);
       this.AddLocationForm.patchValue({
        AddressName : response.results[0].formatted_address
       })
      this.AddLocationForm.get('address')?.patchValue({
        latitude: a.coords.lat,
        longitude: a.coords.lng,
       
  
      })
    })

  
  
            console.log(this.AddLocationForm.value)
           //alert("address" + this.AddLocationForm.get('address')?.invalid);
  
  }
  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
    this.latitude = a.lat
    this.longitude = a.lng


    let x = this.AddLocationForm.get('address')?.patchValue({
      latitude: a.lat,
      longitude: a.lng,


    })
    console.log(x)
  }


}
