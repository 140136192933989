import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-free-create',
  templateUrl: './free-create.component.html',
  styleUrls: ['./free-create.component.scss']
})
export class FreeCreateComponent implements OnInit {
  userTypeList: any[] = []
  isLoading: any
  isOrg: boolean = false
  userForm!: FormGroup
  loading = false;


  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private gs: GlobalserviceService, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      noOfDays: ['', Validators.required],
      freeTrialName:['', Validators.required],
      default:['']
    })
    console.log(this.data)
    if(this.data){
      this.userForm.patchValue({
      noOfDays: this.data.noOfDays,
      freeTrialName:this.data.freeTrialName,
      default:this.data.default
    })
    }
    else {
      this.userForm = this.fb.group({
        noOfDays: ['', Validators.required],
        freeTrialName:['', Validators.required],
        default:['']
      })
    }

  }
  get f() {
    return this.userForm.controls;
  }
  create_user() {
    if(this.userForm.invalid){
      return
    }
    let x = this.userForm.value
    if(x.default == ''){
      x['default']=false
    }

    console.log(x)
    if (this.userForm.invalid) {
      return
    }
  
    let final_data = {
      ...x,
      noOfDays: x.noOfDays,
      status:{
        statusId: Number(1)
      },
      freeTrialName:x.freeTrialName,
      default:x.default
    }
   
    console.log(JSON.stringify(final_data))
    this.loading = true

  

    if(this.data){
      final_data['freeTrialId'] = this.data.freeTrialId
      this.gs.put_free_update(this.data.freeTrialId,final_data).subscribe(
        (data:any)=>{
          console.log(data)
          this.dialog.closeAll()
          Swal.fire({
            text:data.message,
            icon:'success'
          })
          window.location.reload();
        },
        err => {
          this.loading = false
          console.log(err)
          let x = err.error.message
          if (x == undefined) {
            Swal.fire(
              "Check Net:: Connection :: err"
            )
          } else {
            console.log(err.error.message)
            if (err) {
              Swal.fire({
                text: err.error.message,
                icon: 'error'
              })
            }
          }
        },
      )
    }
    else{
      this.gs.addtrial(final_data).subscribe(
        (d: any) => {
          console.log(d)
          this.loading = false
          this.dialog.closeAll()
          Swal.fire({
            text:d.message,
            icon:'success' 
          })
          window.location.reload();
          this.router.navigate(['free-trial']);
        },
        err => {
          this.loading = false
          console.log(err)
          let x = err.error.message
          if (x == undefined) {
            Swal.fire(
              "Check Net:: Connection :: err"
            )
          } else {
            console.log(err.error.message)
            if (err) {
              Swal.fire({
                text: err.error.message,
                icon: 'error'
              })
            }
          }
        },
        () => {
          console.log('done')
        }
      )
    }
  }


  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPressnumber(event: any) {
    const pattern = /^[0-9]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
}






