import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Component({
  selector: 'app-update-location',
  templateUrl: './update-location.component.html',
  styleUrls: ['./update-location.component.scss']
})
export class UpdateLocationComponent implements OnInit {

  id:any;
  location :any = {};
  UpdateLocationForm !: FormGroup
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  geocodedaddress :any;
  isLoading :any;
  
  constructor(private fb: FormBuilder,
    private gs: GlobalserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService) { }

  ngOnInit(): void {

      this.zoom = 15;
    this.UpdateLocationForm = this.fb.group({
      AddressName :  ['', [Validators.required ]],
      address: this.fb.group(
        {
          addressName: [''],
          latitude: ['', Validators.required],
          longitude: ['', Validators.required],
        }
      ),
      status: this.fb.group({
        statusId: 1
      })
    })

    
    

    this.route.paramMap.subscribe((a: any) => {
      let id = a.get('id')
      this.id = id
          console.log("id" + this.id);
      let x: any = this.auth.getLocalStorageData()

      this.gs.getLocation_by_ID(this.id).subscribe((location: any) => {
        console.log(location)
           this.latitude = location.latitude;
           this.longitude = location.longitude;
           this.address = location.addressName;
            
            
             this.UpdateLocationForm.controls['AddressName'].setValue(location.address);
   
           this.UpdateLocationForm.get('address')?.patchValue({
            addressName:location.address,
            latitude: location.latitude,
            longitude: location.longitude
      
      
          })
      }, err => {

       
      })


    })

    console.log(this.id)
  }

  get f() {
    return this.UpdateLocationForm.controls;
  }

  editLocation() {
    console.log(this.UpdateLocationForm.value)
    let x = this.UpdateLocationForm.value


    if (this.UpdateLocationForm.invalid) {
      return
    }
   
  
     
     let user = this.auth.getLocalStorageData()
     let locationdata;
        console.log(user);

         
               locationdata = {
                locationId : this.id,
                latitude : this.latitude,
                longitude: this.longitude,
                address: x.AddressName, 
                
              };
           
            
          this.isLoading = true
          this.gs.Update_Location(locationdata).subscribe((a: any) => {
            
            
            Swal.fire(
              'Location Updated Successfully',
              "",
              'success'
            )
            this.isLoading = false
            this.router.navigate(['/location-list']);
            console.log(a)
          },
          err => {
            console.log
            this.isLoading = false
         
            Swal.fire(
              err.error.message,
              "",
              'error'
            )
          })

  }

  getaddress()
  {
        let address = document.getElementById("address");

        const options = {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
          types: ["establishment"],
        };
      
       // map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
     const autocomplete = new google.maps.places.Autocomplete(address);
   //     const autocomplete = autocompleteapi(address, options);
     //   console.log(autocomplete);

        autocomplete.addListener("place_changed", ()=>{
               this.isLoading = true;
            let place = google.maps.places.PlaceResult = autocomplete.getPlace();

            if(place.geometry === undefined || place.geometry === null) {
              return;
            }
            let lat = place.geometry.location.lat();
          let lng = place.geometry.location.lng();

        // const mark = new google.maps.LatLng(lat,lng);

          this.latitude = lat
          this.longitude = lng
          this.isLoading = false;
        console.log(lat,lng);
        })
  }
  
  
  markerDragEnd(a: any) {
    console.log(a.coords)
    this.latitude = a.coords.lat
    this.longitude = a.coords.lng

    let geocoder = new google.maps.Geocoder;
    let latlng = {lat: this.latitude, lng: this.longitude};
    let that = this;


    geocoder
  .geocode({ location: latlng })
  .then((response :any) => {

    let fromvalue  = this.UpdateLocationForm.value;
    console.log("formvalue" + fromvalue.AddressName);
    this.UpdateLocationForm.patchValue({
      AddressName : response.results[0].formatted_address
     })
  this.UpdateLocationForm.get('address')?.patchValue({
    addressName: fromvalue.AddressName,
    latitude: a.coords.lat,
    longitude: a.coords.lng


  })
          console.log(this.UpdateLocationForm.value)
  })

  
           //alert("address" + this.AddLocationForm.get('address')?.invalid);
  
  }
  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
    this.latitude = a.lat
    this.longitude = a.lng

    let fromvalue  = this.UpdateLocationForm.value;
    console.log("formvalue" + fromvalue.AddressName);

    let x = this.UpdateLocationForm.get('address')?.patchValue({
      addressName:fromvalue.AddressName,
      latitude: a.lat,
      longitude: a.lng,


    })
    console.log(x)
  }

}
