import { Injectable, Output, EventEmitter } from '@angular/core';
import { globalTimeOut, url } from './properties';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { identifierModuleUrl } from '@angular/compiler';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
// import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {
 


  myFile: any;


  constructor(private http: HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  //---------------------getmethod sample---------------------------------

  // usertype
  get_usertype() {
    return this.http.get<any[]>(`${url}user-type`)
  }
  get_user_org(org_id: any) {
    return this.http.get<any[]>(`${url}user/organization/${org_id}`)
  }
  get_primary_users_user(primaryUserId: any) {
    return this.http.get<any[]>(`${url}user/primaryUser/${primaryUserId}`)
  }
  // family Live Location based Api in Manage User
  getCurrentLocation(createdByUser: any) {
    return this.http.get<any[]>(`${url}livelocation/reports/createdByUser/${createdByUser}`)
  }

  // Filter User Api
  get_userlist(userid: any) {
    return this.http.get(`${url}reports/${userid}`)
  }
 // Filter User and sD and ED Api
  get_userandDatelist(startDate:any,endDate:any,userid: any) {
    return this.http.get(`${url}reports/${startDate}/${endDate}/${userid}`)
  }
  // Filter User and sD and ED and STime and ETime Api
  get_userandTimelist(startDate:any,endDate:any,startTime:any,endTime:any,userid: any) {
    return this.http.get(`${url}reports/date/time/${startDate}/${endDate}/${startTime}/${endTime}/${userid}`)
  }


  Get_Country() {
    return this.http.get<any[]>(`${url}country`)
  }
  getEmployeeBusinessTravelRequest_EmailID(reportingto: any, businesssTravelStatus: any) {
    return this.http.get(`${url}business_travel/reportingto/${reportingto}/${businesssTravelStatus}`)
  }

  getCountry() {
    return this.http.get(`${url}country`);
  }
  getState(countryId: any) {
    return this.http.get(`${url}state/${countryId}`);
  }
  getCity(stateId: any) {
    return this.http.get(`${url}city/${stateId}`);
  }
  getIndustryType() {
    return this.http.get(`${url}industry-type`);
  }
  getUser_by_Id(id: any) {
    return this.http.get(`${url}user/${id}`);
  }
  get_organziation() {
    return this.http.get(`${url}organization`);
  }
  getUser_by_location(primaryUserId: any, userId: any) {
    return this.http.get(`${url}work-place/${primaryUserId}/${userId}`);
  }

  addOrganization(data: any) {
    return this
      .http
      .post(`${url}organization/superadmin/registration`, data);
  }

  adduser(data :any) {
    return this
    .http
    .post(`${url}superadmin/user`, data);
  }

  forgetpassword(data: any) {
    return this
    .http
    .post(`${url}forgotpassword`, data);
  }

  forgetpasswordcode(data: any) {
    return this
    .http
    .post(`${url}login/forgotpassword`, data);
  }

  uploaduser(formData: FormData) {
    return this
      .http
      .post(`${url}user/uploadfile`, formData);
  }
  multipleuser(final_json: any) {
    return this
    .http
    .post(`${url}multiple/user`, final_json);
  }

   getuserlocation(userId: any) {
    
    return this
    .http
    .get(`${url}livelocation/${userId}`);

    
  }
 

  RegisetrOrgUser(data: any) {
    return this
      .http
      .post(`${url}organization/user/registration`, data);
  }

  Create_Org_User(data: any) {
    return this
      .http
      .post(`${url}user`, data);
  }

  Assign_Location(data: any) {
    return this
      .http
      .post(`${url}multiple/work-place`, data);
  }

  Assign_Instant_Location(f_d: any) {
    return this
    .http
    .post(`${url}multiple/instant/work-place`, f_d);
  }

  get_user_induvar(){
    return this.http.get(`${url}primaryuser`);
  }


  get_location(userId: any) {
    return this.http.get(`${url}locationDetails/user/${userId}`);
  }














  // leaveType
 
  // getWFHRequest(applyTo, categoryId, status) {
  //   return this.http.get(`${url}wfh/${applyTo}/${categoryId}/${status}`)
  // }
  getWFHRequest(reportingTo: any, status: any) {
    return this.http.get(`${url}wfh/reportingto/${reportingTo}/${status}`)
  }
  getPermission_Manage(id: any) {
    return this.http.get(`${url}permissions/pending/reporting/${id}`)
  }
  // get travel mode
  getTravel_Mode() {
    return this.http.get(`${url}travelmode`);
  }
  // get business travel
  getMyBusinessTravel_List(empId: any, categoryId: any) {
    return this.http.get(`${url}business_travel/${empId}/${categoryId}`)
  }

  getListTypeById(listTypeId: any) {
    return this.http.get(`${url}list-type-values/${listTypeId}`)
  }
  getAdjustment() {
    return this.http.get(`${url}leave_adjustment/organization/1`);
  }
  getOrganization() {
    return this.http.get(`${url}organization`);
  }
  //--------------------------------post------------------------------------
  // business apply
  Apply_Business_Travel_Request(data: any) {
    return this.http.post(`${url}business_travel`, data)
  }
  // action
  //
  createLeaveType(data: any) {
    return this.http.post(`${url}leave_type`, data)
  }
  // withdraw
  withdraw(data: any) {
    return this.http.post(`${url}leave_withdraw`, data)
  }
  //leave Balance
  leaveBalance(data: any) {
    return this.http.post(`${url}leave_balance`, data)
  }
  // employee
  CreateEmployee(Form: FormData) {
    return this
      .http
      .post(`${url}employee`, Form);
  }
  addLeave(Form: any) {
    return this
      .http
      .post(`${url}leave_request`, Form);
  }
  applyPermission(Form: any) {
    return this
      .http
      .post(`${url}permissions`, Form);
  }
  // wrok from home
  addWfm(Data: any) {
    return this
      .http
      .post(`${url}wfh`, Data);
  }
  addAdjustment(Data: any) {
    return this
      .http
      .post(`${url}leave_adjustment`, Data);
  }

  //--------------------------------update------------------------------------
  //  leave status update
  UpdateLeaveStatus(id: any, Form: any) {
    return this
      .http
      .put(`${url}leave_request_status/${id}`, Form);
  }
  updateLeaveType(id: any, data: any) {
    return this.http.put(`${url}leave_type/${id}`, data)
  }
  UpdateEmployee(id: any, data: FormData) {
    return this.http.put(`${url}employee/${id}`, data)
  }

  UpdateEmployeeProfile(id: any, data: any) {
    return this.http.put(`${url}employee_image/${id}`, data)
  }
  // update Password
  updateAccountSettings(id: any, Form: any) {
    return this
      .http
      .put(`${url}change_password/${id}`, Form);
  }
  Actionwfh(id: any, data: any) {
    return this
      .http
      .put(`${url}wfh_status/${id}`, data);
  }
  AcctionBusiness(id: any, data: any) {
    return this
      .http
      .put(`${url}business_travel_status/${id}`, data);
  }
  //--------------------------------delete------------------------------------
  deleteLeaveType(id: any) {
    return this.http.delete(`${url}leave_type/${id}`)
  }
  deleteEmployee(employeeId: number) {
    return this
      .http
      .delete<any>(`${url}employee/${employeeId}`);
  }
  deleteLeave(leaveId: any, data: any) {
    return this
      .http
      .put(`${url}leave_request_status/${leaveId}`, data);
  }
  // +++++++++++++++++++++++++++++ withdraw ++++++++++++++++++++++++++++++++++++++++++++++//

  employee_withdraw_list_action(empId: any, mail: any) {
    return this.http.get(`${url}leave_withdraw/employee/${empId}/${mail}`)

  }
  withdrawAction(id: any, data: any) {
    return this.http.put(`${url}leave_withdraw_status/${id}`, data)
  }

  Permission_Status(id: any, data: any) {
    return this.http.put(`${url}permissions_status/${id}`, data)
  }

  getAll_withdrawList(mail: any) {
    return this.http.get(`${url}leave_withdraw/all/${mail}`)

  }

  post_subscribe(updatetoken :any) {
    return this
    .http
    .post(`${url}doNotTrack/subscribe`, updatetoken);
}

Create_Location(locationdata : any) {
  return this
  .http
  .post(`${url}locationDetails/user`, locationdata);
}

getLocation_by_ID(id: any) {
  return this.http.get(`${url}locationDetails/location/${id}`)

}

Update_Location(locationdata: any) {
  return this
  .http
  .put(`${url}locationDetails/${locationdata.locationId}`, locationdata);
}

get_freetrial() {
  return this.http.get(`${url}freetrial/`)
}

get_freetrial_default() {
  return this.http.get(`${url}freetrial/isdefault`);
}

get_free_trail(){
  return this.http.get(`${url}freetrial`);
}
get_free_trailid(freetrialId:any){
  return this.http.get(`${url}freetrial/${freetrialId}`); 
}
put_free_trailid(freetrialId:any ,msg: any){
  return this.http.put(`${url}freetrial/${freetrialId}`,msg); 
}

addtrial(data: any){
  return this
  .http
  .post(`${url}freetrial`, data);
}

put_free_update(freetrialId:any ,msg: any){
  return this.http.put(`${url}freetrial/update/${freetrialId}`,msg); 
}

get_dont_track_byid(id: any) {
  return this.http.get<any>(`${url}track/${id}`)
}

get_dont_track(primaryUserId: any) {
  return this.http.get<any[]>(`${url}track/byprimaryuser/${primaryUserId}`)
}
put_dont_track(trackId: any, msg: any) {
  return this.http.put<any>(`${url}track/${trackId}`,msg)
}

Update_Org_User(z: any, userId: any) {
  return this.http.put<any>(`${url}user/${userId}`,z)
}

get_report_byuser(fromDate: any, toDate: any, id: any) {
  return this.http.get<any[]>(`${url}reports/${fromDate}/${toDate}/${id}`)
}

get_current_workplace(today: string | null, id: any) {
  return this.http.get<any[]>(`${url}work-place/current/${today}/${id}`)
}
// Manage Geofence
get_Gefence_list(userId:number) {
  return this.http.get<any[]>(`${url}geofence/user/${userId}`)
}
// add Geofence
createGeofence(data: any) {
  return this.http.post(`${url}geofence`, data)
}
// tatus active and inactive api
updateGeofenceStatus(id: any, status: any) {
  return this
    .http
    .put(`${url}geofence/status/${id}/${status}`, status);
}

updateGeofence(geofenaceId: any, updateData: any) {
  return this.http.put<any>(`${url}geofence/${geofenaceId}`,updateData)
}

getGeofenceById(geofenaceId: number) {
  return this.http.get(`${url}geofence/${geofenaceId}`)
}
// Assign Geofence
get_User_list() {
  return this.http.get<any[]>(`${url}user`)
}
get_Geofence_list(userId: number) {
  return this.http.get<any[]>(`${url}geofence/user/status/${userId}`)
}
get_usergeofencelist(userid: any) {
  return this.http.get<any>(`${url}userGeofence/user/${userid}`)
}
get_geofencelist(geofenceId: any) {
  return this.http.get<any>(`${url}userGeofence/geofence/${geofenceId}`)
}
get_userandgeofencelist(geofenceId: any,userid: any) {
  return this.http.get<any>(`${url}userGeofence/geofence/user/${geofenceId}/${userid}`)
}
// Assign Geofence 
createassignGeofence(data: any) {
  return this.http.post(`${url}userGeofence/multiple`, data)
}
// active and inactive status
// updateAssignGeofenceStatus(id: any, status: any) {
//   let result =  this.http.put<any>(`${url}employeeclientassign/deactive/${id}/${status}`).pipe(map(data=>data))
//   return result;
// }
updateAssignGeofenceStatus(id: any, status: any) {
  return this
    .http
    .put(`${url}userGeofence/status/${id}/${status}`, status);
}

}
