import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-track-user-list',
  templateUrl: './view-track-user-list.component.html',
  styleUrls: ['./view-track-user-list.component.scss']
})
export class ViewTrackUserListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'phoneNumber', 'userType', 'select'];
  dataSource: any = new MatTableDataSource;
  selection: any = new SelectionModel<any>(true, []);

  selectedCount: any = 0
  isLoading: boolean = false

  constructor(public dialogRef: MatDialogRef<ViewTrackUserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private Gs: GlobalserviceService,
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }


  viewLogs(a: any) {
    console.log(a)
    this.dialog.closeAll()
    this.router.navigate(['track-report', a.userId])
  }





}
