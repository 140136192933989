import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MessagingService } from 'src/app/service/messaging.service';
import Swal from 'sweetalert2';
import { AddressComponent } from '../../address/address.component';
import { UserService } from '../../user/user.service';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  displayedColumns: string[] = ['locationId', 'latitude', 'longitude', 'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;
  currentPageOffset: number = 0; 
  constructor(public dialog: MatDialog,  private gs: UserService,  private Gs: GlobalserviceService ,private auth: AuthService , private router : Router ,private messagingService: MessagingService) { }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {

    let user = this.auth.getLocalStorageData()
       console.log(user);

      this.Gs.get_location(user.userId).subscribe((a: any) => {
        this.dataSource = new MatTableDataSource(a);
        console.log(a)
        this.dataSource.paginator = this.paginator;
      })

  }

  editLocation(element:any){
    this.router.navigate(['edit-location', element.locationId])
  }

  viewaddress(element:any)
  {
    const dialogRef = this.dialog.open(AddressComponent, {
      disableClose: false,
      width: '400px',
      data : element,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }
}
