<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading">
    <app-loading></app-loading>
    <br>
    <h1>Please Wait Loading...</h1>
  </div>
</ng-container>
<ng-template #elseTemplate>
    <h2 mat-dialog-title>Update User</h2>
    <form action="" [formGroup]="userForm">
      <!-- name -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-icon matPrefix style="color:#6d6b6b;">person</mat-icon>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" placeholder="name.."  [errorStateMatcher]="matcher"  
          (keypress)="omit_special_char($event) && keyPress($event)">
        <mat-error *ngIf="f.name.hasError('required')">
          Please enter <strong> name</strong>
        </mat-error>
      </mat-form-field>
      <!-- phone number -->
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Contact</mat-label>
        <mat-icon style="color: #6d6b6b;transform: translateY(0px);" matPrefix>phone</mat-icon>
        <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
          formControlName="mobileNumber" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="f.mobileNumber.hasError('required')">Please Enter <strong>Contact</strong> </mat-error>
        <mat-error *ngIf="f.mobileNumber.hasError('minlength')">Enter <strong>Valid Number</strong></mat-error>
      </mat-form-field>
      <!-- mail id -->
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Organization Mail-Id</mat-label>
        <mat-icon style="color: #6d6b6b;
                              transform: translateY(0px);" matPrefix>email</mat-icon>
        <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
          [errorStateMatcher]="matcher" />
        <mat-error *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="f.email.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <!-- password -->
      <!-- <mat-form-field class="full-width" appearance="fill">
        <mat-label>Password</mat-label>
        <mat-icon style="color: #6d6b6b;
                              transform: translateY(0px);" matPrefix>lock</mat-icon>
        <input matInput type="text" formControlName="password" placeholder="****" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="f.password.hasError('required')">
          password is <strong>required</strong>
        </mat-error>
      </mat-form-field> -->
      <!-- userType -->
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Select User Type</mat-label>
        <mat-icon style="    color: #6d6b6b;
                    transform: translateY(0px);" matPrefix>account_box</mat-icon>
        <mat-select  formControlName="userType"  [errorStateMatcher]="matcher">
          <ng-container *ngIf="userTypeList.length > 0;else elseUserType">
            <mat-option *ngFor="let u of userTypeList " [value]="u.userTypeId">
              {{u.userTypeName}}
            </mat-option>
          </ng-container>

          <ng-template #elseUserType>
            <mat-option>
              {{"No User List Found"}}
            </mat-option>
          </ng-template>

        </mat-select>
        <mat-error *ngIf="f.userType.hasError('required')">
          Please Select <strong>User Type</strong>
        </mat-error>
      </mat-form-field>

      <div class="form-group" align="center">
        <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button" mat-button
          mat-dialog-close>CANCEL</button>
        <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit"
          [disabled]="userForm.invalid" (click)="update_user()">
          UPDATE
        </button>
      </div>
    </form>

</ng-template>
