import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { AuthService } from '../Authenticaton/auth.service';
import { GlobalserviceService } from '../providers/globalservice.service';
import * as firebase from 'firebase';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
   firebasetoken :any;
  notificationlist : any = [];
 // messaging = firebase.default.messaging();

  constructor(private angularFireMessaging: AngularFireMessaging ,  private auth: AuthService , private globel:GlobalserviceService) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);    
      }
    )
  }
  requestPermission() { 
 //  alert("Requst permission")
   let user = this.auth.getLocalStorageData();
   
   if(user != null  )
   {

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token); 
          this.firebasetoken = token;

          
  if(token != null)
  {
     //  alert("has token")
    if(user.userType[0].userTypeId == 1 || user.userType[0].userTypeId == 2)
    {
        let updatetoken = {
       
            subscriptionId: token,
            user:{
                userId:user.userId
            }
       
    };
       

        this.globel.post_subscribe(updatetoken).subscribe((a: any) => {
          }
          ,
            err => {
            })
    } 
  }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
    
   }
  


    
  }
  receiveMessage() {
     // alert("function called")
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
         console.log("current message")
        console.log(this.currentMessage)

        
         
        let x: any = localStorage.getItem("notifications");
        let y = JSON.parse(x)
           console.log("notifications");
           console.log(x);   

              if(x !=  null){
                if(x.length != 0)
                {
                  //alert("x.lengtj " + y.length)
                 this.notificationlist = y;
                }
              
              }

           

           

        console.log("message data")
        console.log(payload.data);
        console.log(payload.notification);

              let paydata = 
              {
                  data : JSON.parse(payload.data.data),
                  notification : payload.notification
              }

              this.notificationlist.push(paydata);

        localStorage.setItem('notifications', JSON.stringify( this.notificationlist)); 
      })


      
  } 

  backgroundmessage()
  {
    // this.angularFireMessaging.setBackgroundMessageHandler((payload) => {
    //   console.log("back ground Message received. ", payload);
      
    // });
  }
 
deletetoken(){
 // alert("delete function called")
 // alert("this.firebasetoken " + this.firebasetoken)
  this.angularFireMessaging.deleteToken(this.firebasetoken).subscribe(resp => {
    return;
  },
  (err) => {
    console.error('delete token .', err);
  })
  
}


processMessage(event: MessageEvent<any>) {


//  alert("function called ")
    console.log(event);

    this.angularFireMessaging.onBackgroundMessage

}


}