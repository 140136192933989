

<mat-card class="col-md-8">
  <br>
  <div class="row">
    <div class="col-md-10">
      <mat-card-title>
      Assign Location
    </mat-card-title></div>
    <div class="col-md-2">
      <button  mat-stroked-button mat-flat-button *ngIf="showmaps" (click) = "Showmap()" style="padding: 5px;background-color:honeydew;" ><span class="material-icons">fmd_good</span><span style="margin-left: 3px;">showmap</span></button>
      <button  mat-stroked-button mat-flat-button *ngIf="hidemap" (click) = "Hidemap()" style="padding: 5px;background-color:honeydew;"><span class="material-icons">wrong_location</span><span style="margin-left: 3px;">hidemap</span></button>
    </div>
  </div>
  <br>

 <div  *ngIf="showlocation" >

    <form   action="" [formGroup]="AssignLocationForm" (ngSubmit)="assignLocation()">

      <!-- from date -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>&nbsp; From Date</mat-label>
        <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
        <input matInput (click)="picker.open()" (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher"
          formControlName="fromDate" [matDatepicker]="picker" placeholder="select start date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
        <mat-error *ngIf="f.fromDate.hasError('required')">
          Please select <strong>From Date</strong>
        </mat-error>
      </mat-form-field>
  
      <!-- todate -->
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>&nbsp; To Date</mat-label>
        <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
        <input matInput (click)="a.open()" [min]="min" [errorStateMatcher]="matcher" formControlName="toDate"
          [matDatepicker]="a" placeholder="select end date" placeholder="select end date" [disabled]="endDate_disable" />
        <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
        <mat-datepicker #a touchUi></mat-datepicker>
        <mat-error *ngIf="f.toDate.hasError('required')">
          Please select <strong>To Date</strong>
        </mat-error>
      </mat-form-field>
  
      
  
         
      <div  *ngIf="showlocation"  formGroupName="location">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Location</mat-label>
          <mat-select id="locationId" formControlName="locationId" [errorStateMatcher]="matcher">
            <ng-container *ngIf="locationList.length > 0 ; else countryNodata">
              <mat-option *ngFor="let u of  locationList" [value]="u.locationId" >
                {{u.address}}
              </mat-option>
            </ng-container>
            <ng-template #countryNodata>
              <mat-option>
                {{'No Location List Found'}}
              </mat-option>
            </ng-template>
          </mat-select>
          <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.location.invalid">
            Please select <strong>location</strong>
          </mat-error>
        </mat-form-field>
      </div>
  
    
  
      <!-- start time -->
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Start Time</mat-label>
            <input type="text" matInput formControlName="startTime" (click)="toggleStartTimepicker.open()"
              [errorStateMatcher]="matcher" [ngxTimepicker]="toggleStartTimepicker" [disableClick]="true" readonly>
            <ngx-material-timepicker-toggle matSuffix [for]="toggleStartTimepicker">
            </ngx-material-timepicker-toggle>
            <ngx-material-timepicker #toggleStartTimepicker [enableKeyboardInput]="true"></ngx-material-timepicker>
            <mat-error *ngIf="f.startTime.hasError('required')">
              Please select <strong>Start Time</strong>
            </mat-error>
          </mat-form-field>
        </div>
  
        <!-- end time -->
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>End Time</mat-label>
            <input type="text" matInput formControlName="endTime" (click)="toggleEndTimepicker.open()"
              [errorStateMatcher]="matcher" [ngxTimepicker]="toggleEndTimepicker" [disableClick]="true" readonly>
            <ngx-material-timepicker-toggle matSuffix [for]="toggleEndTimepicker">
            </ngx-material-timepicker-toggle>
            <ngx-material-timepicker #toggleEndTimepicker [enableKeyboardInput]="true"></ngx-material-timepicker>
            <mat-error *ngIf="f.endTime.hasError('required')">
              Please select <strong>End Time</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
  
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>&nbsp; Tracking Time Interval(HH:MM)</mat-label>
        <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>schedule</mat-icon> -->
        <input matInput type="text" autocorrect="off" autocapitalize="off" spellcheck="off" formControlName="timeInterval"
          [errorStateMatcher]="matcher" mask="00-00" placeholder="Enter time interval per day">
        <mat-error *ngIf="f.endTime.hasError('required')">
          Please enter <strong>time interval</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <div>
        <button mat-stroked-button color="warn" style="width: 100%; margin: 20px 0 0 8px;" type="submit" mat-button>Select
          User</button>
      </div>
    </form>
   </div>
 

     <div  *ngIf="showmap" >

      <form action=""   [formGroup]="AssignInstantLocationForm" (ngSubmit)="assigninstantLocation()">

        <!-- from date -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>&nbsp; From Date</mat-label>
          <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
          <input matInput (click)="picker.open()" (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher"
            formControlName="fromDate" [matDatepicker]="picker" placeholder="select start date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
          <mat-error *ngIf="ff.fromDate.hasError('required')">
            Please select <strong>From Date</strong>
          </mat-error>
        </mat-form-field>
    
        <!-- todate -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>&nbsp; To Date</mat-label>
          <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
          <input matInput (click)="a.open()" [min]="min" [errorStateMatcher]="matcher" formControlName="toDate"
            [matDatepicker]="a" placeholder="select end date" placeholder="select end date" [disabled]="endDate_disable" />
          <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
          <mat-datepicker #a touchUi></mat-datepicker>
          <mat-error *ngIf="ff.toDate.hasError('required')">
            Please select <strong>To Date</strong>
          </mat-error>
        </mat-form-field>
    
      
        <div >
          <div class="form-group">
            <label> Address</label>
            <input type="text"  formControlName="AddressName" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter  Location"
              autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
              <mat-error *ngIf="ff.AddressName.hasError('required')">
                Please select <strong>Address</strong>
              </mat-error>
              <mat-error *ngIf="ff.AddressName.hasError('pattern') ">
                Please enter a valid  address
              </mat-error>
          </div>
          <br>
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="mapClicked($event)">
            <agm-marker [latitude]="latitude" [longitude]="longitude" (dragEnd)="markerDragEnd($event)"
              [markerDraggable]="true"></agm-marker>
          </agm-map>
    
          <h5>Address: {{address}}</h5>
          <div>Latitude: {{latitude}}</div>
          <div>Longitude: {{longitude}}</div>
        </div>
    
        <div style="color: red;" *ngIf="ff.address.invalid">
          Please select the location to track
        </div>
    
      
    
        <!-- start time -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Start Time</mat-label>
              <input type="text" matInput formControlName="startTime" (click)="toggleStartTimepicker.open()"
                [errorStateMatcher]="matcher" [ngxTimepicker]="toggleStartTimepicker" [disableClick]="true" readonly>
              <ngx-material-timepicker-toggle matSuffix [for]="toggleStartTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker #toggleStartTimepicker [enableKeyboardInput]="true"></ngx-material-timepicker>
              <mat-error *ngIf="ff.startTime.hasError('required')">
                Please select <strong>Start Time</strong>
              </mat-error>
            </mat-form-field>
          </div>
    
          <!-- end time -->
          <div class="col-md-6">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>End Time</mat-label>
              <input type="text" matInput formControlName="endTime" (click)="toggleEndTimepicker.open()"
                [errorStateMatcher]="matcher" [ngxTimepicker]="toggleEndTimepicker" [disableClick]="true" readonly>
              <ngx-material-timepicker-toggle matSuffix [for]="toggleEndTimepicker">
              </ngx-material-timepicker-toggle>
              <ngx-material-timepicker #toggleEndTimepicker [enableKeyboardInput]="true"></ngx-material-timepicker>
              <mat-error *ngIf="ff.endTime.hasError('required')">
                Please select <strong>End Time</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
    
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>&nbsp; Tracking Time Interval(HH:MM)</mat-label>
          <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>schedule</mat-icon> -->
          <input matInput type="text" autocorrect="off" autocapitalize="off" spellcheck="off" formControlName="timeInterval"
            [errorStateMatcher]="matcher" mask="00-00" placeholder="Enter time interval per day">
          <mat-error *ngIf="ff.endTime.hasError('required')">
            Please enter <strong>time interval</strong>
          </mat-error>
        </mat-form-field>
        <br>
        <div>
          <button mat-stroked-button color="warn" style="width: 100%; margin: 20px 0 0 8px;" type="submit" mat-button>Select
            User</button>
        </div>
      </form>
     </div>

 
</mat-card>