import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  constructor(public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
   
    console.log("addresss")
    console.log(this.data)
  }

}
