import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { UserService } from '../user/user.service';


@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit {
  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber','fromDate','toDate','approvalStatus','action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  currentPageOffset: number = 0; 
  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;

  constructor(public dialog: MatDialog, private gs: UserService,
    private Gs: GlobalserviceService, private auth: AuthService) {
  }

  ngOnInit() {
    let id = this.auth.getLocalStorageData()?.userId;
    console.log(this.gs.userList)
    this.Gs.get_dont_track(id).subscribe(
      (d: any) => {
        console.log(d)
        this.dataSource = new MatTableDataSource(d)
        this.dataSource.paginator = this.paginator;
      }
    )

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  approve(e:any){
    
    console.log(e)
    e['approvalStatus'] ={
      approvalStatusId:2
    }
    this.Gs.put_dont_track(e.trackId,e).subscribe(
      
      (d:any)=>{
        window.location.reload();
        console.log(d)
      },
      err=>{
        console.log(err)
      }
    )
  }
  denied(e:any){
    e['approvalStatus'] ={
      approvalStatusId:3
    }
    this.Gs.put_dont_track(e.trackId,e).subscribe(
      (d:any)=>{
        window.location.reload();
        console.log(d)
      },
      err=>{
        console.log(err)
      }
    )

  }

  onPageChange(event: any) {
    // console.log("index",event.pageIndex);
    // console.log("size",event.pageSize);
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }
}