import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
//import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

import Swal from 'sweetalert2';
import { UserService } from '../user/user.service';
import {url} from '../../providers/properties'

declare var google: any;

@Component({
  selector: 'app-live-track',
  templateUrl: './live-track.component.html',
  styleUrls: ['./live-track.component.scss']
})
export class LiveTrackComponent implements OnInit {

 
  
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  isLoading: boolean = false
  webSocketEndPoint: string = `${url}getLiveLocation`;
  stompClient: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, //private websoc : WebSocket,
    public dialog: MatDialog,  private gs: UserService,  private Gs: GlobalserviceService ,private auth: AuthService) { }

    title: string = 'AGM project';
    latitude: any;
    longitude: any;
    zoom: any;
    address: any;
    marlatitude : any;
    marlongitude: any;
    map: any;
    Marker:any;
    userurl:any;

    
  ngOnInit(): void {
    console.log(this.data)
    // this.dataSource = this.gs.userList
    this.latitude = 28.644800;
    this.longitude = 77.216721;

      this.marlatitude  = 12.7409 ;
      this.marlongitude = 77.8253;
     
    this.zoom = 15;
         this.userurl = "/liveLocation/"+this.data.userId;
         console.log("this.userurl"+ this.userurl);
     console.log("Initialize WebSocket Connection");
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        const _this = this;
        _this.stompClient.connect({}, function (frame: any) {
            _this.stompClient.subscribe(_this.userurl, function (sdkEvent: any) {
                _this.onMessageReceived(sdkEvent);
            });
            //_this.stompClient.reconnect_delay = 2000;
        }, this.errorCallBack);
       
  }

  errorCallBack(error: string) {
    console.log("errorCallBack -> " + error)
    setTimeout(() => {
      
    
    }, 5000); 
}


onMessageReceived(message: any) {
  console.log("Message Recieved from Server :: " + message);
        let LatLngdata = JSON.parse(message.body);
      console.log(this.Marker);
        if(this.Marker != undefined)
        {
              this.Marker.setMap(null);
        }
    
  const mark = new google.maps.LatLng(LatLngdata.latitude,LatLngdata.longitude);
   
  this.map.setCenter({ lat: LatLngdata.latitude, lng: LatLngdata.longitude});
  this.Marker = new google.maps.Marker
  ({
   position: mark,
   map: this.map,
   title: ""
  });
  
}


  onMapReady(map: any) {
    console.log(map);
    this.map = map;
    this.Gs.getuserlocation(this.data.userId).subscribe((a: any) => {
   
         console.log(a);
         let latuserdata = a;
      const mark = new google.maps.LatLng( latuserdata[0].latitude, latuserdata[0].longitude);
     
      console.log(this.map)
      this.map.setCenter({ lat: latuserdata[0].latitude, lng: latuserdata[0].longitude });
       this.Marker = new google.maps.Marker({position: mark, map: this.map});

       this.Marker.addListener("click", () => {
           this.getaddress();
      });
    },
    err => {
     
      Swal.fire(
        err.error.description,
        "",
        'error'
      )
    })
    
       

  }


 getaddress()
 {
    //alert("function called");
    
      console.log("lat" + this.Marker.getPosition().lat());
      console.log("long" + this.Marker.getPosition().lng());
  let geocoder = new google.maps.Geocoder;
  let latlng = {lat: this.Marker.getPosition().lat(), lng: this.Marker.getPosition().lng()};
  let that = this;
  geocoder.geocode({'location': latlng}, function(results: any) {
        console.log(results)
      if (results[0]) {            
         let currentLocation = results[0].formatted_address;
         console.log('currentLocation' + currentLocation);
      } else {
        console.log('No results found');
        //that.Marker.set('labelContent', 'sdda');
      }
  });
 }
 

  markerDragEnd(a: any) {
    console.log(a.coords)
    this.latitude = a.coords.lat
    this.longitude = a.coords.lng

  
  }

  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
  


    let x = {
     
      latitude: a.lat,
      longitude: a.lng,


    };
    console.log(x)
  }
  
  closedialog(){

    if (this.stompClient !== null) {
      this.stompClient.disconnect();
  }
  console.log("Disconnected");
     this.dialog.closeAll();
  }

  
}
