<div class="login_container">


  <button class="login_label" [routerLink]="['/login']" mat-raised-button>Login</button>

  <div class="col-md-8">
    <!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
      {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
    </button> -->

    <ng-container *ngIf="loading; else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2>Please Wait... Registering!</h2>
      </div>
    </ng-container>
    <ng-template #elseComponent>
      <mat-horizontal-stepper [linear]="isLinear" #stepper style="background-color: #6d6b6b00;">
        <mat-step [stepControl]="signUpForm">
          <ng-template matStepLabel>Organization</ng-template>
          <form action="" [formGroup]="signUpForm">
            <div class="overflow_">
              <!-- organization name -->
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Organization Name</mat-label>
                <mat-icon matPrefix style="
                            transform: translateY(0px);">apartment</mat-icon>
                <input matInput type="text" formControlName="organizationName" placeholder="Organization name"
                  [errorStateMatcher]="matcher" (keypress)="omit_special_char($event) && keyPress($event)">
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.organizationName.hasError('required')">
                  Please enter <strong>organization name</strong>
                </mat-error>
              </mat-form-field>

              <!-- industry type -->
              <div formGroupName="industryType">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select Industry Type</mat-label>
                  <mat-icon style="
                              transform: translateY(0px);" matPrefix>construction</mat-icon>
                  <mat-select id="industryTypeId" formControlName="industryTypeId" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let u of  industrytypeList" [value]="u.industryTypeId">
                      {{u.industryTypeName}}
                    </mat-option>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.industryType.invalid">
                    Please select <strong>Industry Type</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- select fiscal year  -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>&nbsp; Fiscal Year Start</mat-label>
                <mat-icon style="transform: translateY(0px)" matPrefix>today</mat-icon>
                <input matInput type="text" placeholder="Enter Fiscal Year (YYYY)" [errorStateMatcher]="matcher"
                  formControlName="fiscalYearStart">
                <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.fiscalYearStart.hasError('required')">
                  Please enter <strong>year</strong>
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.fiscalYearStart.hasError('pattern')"> Enter
                  <strong> valid year</strong>
                </mat-error>
              </mat-form-field>
              <!-- org domain -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Organization Domain</mat-label>
                <mat-icon style="
                          transform: translateY(0px);" matPrefix>domain</mat-icon>
                <input matInput type="text" formControlName="organizationDomain" placeholder="jesperapps.com"
                  [errorStateMatcher]="matcher" />
                <mat-error style="color: rgb(0, 0, 0);"
                  *ngIf="f.organizationDomain.hasError('pattern')">
                  Please enter a valid domain name
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.organizationDomain.hasError('required')">
                  Domain name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <!-- org mail id -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Organization Mail-Id</mat-label>
                <mat-icon style="
                          transform: translateY(0px);" matPrefix>email</mat-icon>
                <input matInput type="text" formControlName="contactEmail" placeholder="ex@jesperapps.com"
                  [errorStateMatcher]="matcher" />
                <mat-error style="color: rgb(0, 0, 0);"
                  *ngIf="f.contactEmail.hasError('pattern') && !f.contactEmail.hasError('required')">
                  Please enter a valid email address
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.contactEmail.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <!-- Country -->
              <div formGroupName="country">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select Country</mat-label>
                  <mat-icon style="
                                transform: translateY(0px);" matPrefix>flag</mat-icon>
                  <mat-select id="countryId" formControlName="countryId" [errorStateMatcher]="matcher">
                    <ng-container *ngIf="countryList.length > 0 ; else countryNodata">
                      <mat-option *ngFor="let u of  countryList" [value]="u.countryId" (click)="getState(u)">
                        {{u.countryName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #countryNodata>
                      <mat-option>
                        {{'No Country List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.country.invalid">
                    Please select <strong>country</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- state -->
              <div formGroupName="states">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select State</mat-label>
                  <mat-icon style="
                                transform: translateY(0px);" matPrefix>holiday_village</mat-icon>
                  <mat-select id="stateId" [errorStateMatcher]="matcher" formControlName="stateId">

                    <ng-container *ngIf="stateList.length > 0; else stateNodata">
                      <mat-option *ngFor="let u of  stateList" [value]="u.stateId" (click)="getCity(u.stateId)">
                        {{u.stateName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #stateNodata>
                      <mat-option>
                        {{'No state List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf=" ff.state.invalid">
                    Please select <strong>state</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- City -->
              <div formGroupName="city">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select City</mat-label>
                  <mat-icon style="transform: translateY(0px);" matPrefix>location_city</mat-icon>
                  <mat-select id="cityId" formControlName="cityId" [errorStateMatcher]="matcher">
                    <ng-container *ngIf="cityList.length > 0; else cityNodata">
                      <mat-option *ngFor="let u of  cityList" [value]="u.cityId">
                        {{u.cityName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #cityNodata>
                      <mat-option>
                        {{'No City List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf=" ff.city.invalid">
                    Please select <strong>city</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- phone number -->

              <div class="phone">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-icon style="transform: translateY(0px);" matPrefix>phone</mat-icon>
                  <div style="position: absolute;">
                    {{'+' + selectedCountry_Code}}
                  </div>
                  <input matInput type="text" placeholder="Enter Contact Number" maxlength="10" (keypress)="noNumber($event)"
                    style="padding: 0px 0 0 33px ;" [disabled]='true' formControlName="contactPhoneNumber"
                    [errorStateMatcher]="matcher" />
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.contactPhoneNumber.hasError('required')">Please enter
                    <strong>contact</strong>
                  </mat-error>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.contactPhoneNumber.hasError('minlength')"> enter
                    <strong> valid number</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- gst number -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>GST Number</mat-label>
                <mat-icon style="transform: translateY(0px);" matPrefix>receipt</mat-icon>
                <input matInput type="text" placeholder="Enter GST Number" formControlName="gstNumber" maxlength="15"
                  [errorStateMatcher]="matcher" />
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.gstNumber.hasError('required')">Please Enter
                  <strong>GST number</strong>
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.gstNumber.hasError('minlength')"> Enter
                  <strong> valid GST number</strong>
                </mat-error>
              </mat-form-field>

              <!-- postal code -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Postal Code</mat-label>
                <mat-icon style="transform: translateY(0px);" matPrefix>fiber_pin</mat-icon>
                <input matInput type="text" placeholder="Enter Postal Code" maxlength="10"
                  formControlName="postalCode" [errorStateMatcher]="matcher" />
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.postalCode.hasError('required')">Please Enter
                  <strong>postal code</strong>                
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.postalCode.hasError('pattern')">Please Enter
                  <strong>valid postal code</strong>                
                </mat-error>
                <!-- <mat-error *ngIf="f.phoneNumber.hasError('minlength')"> Enter <strong> Valid Number</strong></mat-error> -->
              </mat-form-field>

              <!-- address -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>&nbsp; Address </mat-label>
                <!-- <mat-icon style=" transform: translateY(0px)" matPrefix>home</mat-icon> -->
                <textarea matInput type="text" formControlName="address" placeholder="Enter Address" rows="10"
                  [errorStateMatcher]="matcher"></textarea>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.address.hasError('required')">
                  Please enter <strong>address</strong>
                </mat-error>
              </mat-form-field>
              <div align="end">
                <button style="margin-right: 51px;" mat-button matStepperNext type="submit">Next</button>
              </div>

            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="userRegisterForm">
          <ng-template matStepLabel>User</ng-template>
          <form [formGroup]="userRegisterForm">
            <div class="overflow_">
              <!-- user name -->
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Full Name</mat-label>
                <mat-icon matPrefix style="
                              transform: translateY(0px);">person</mat-icon>
                <input matInput type="text" formControlName="name" placeholder="name" [errorStateMatcher]="matcher"
                  (keypress)="omit_special_char($event) && keyPress($event)">
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.name.hasError('required')">
                  Please enter <strong>full name</strong>
                </mat-error>
              </mat-form-field>

              <!-- mail id -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Organization Mail-Id</mat-label>
                <mat-icon style="
                            transform: translateY(0px);" matPrefix>email</mat-icon>
                <input matInput type="text" formControlName="email" placeholder="x@jesperapps.com"
                  [errorStateMatcher]="matcher" />
                <mat-error style="color: rgb(0, 0, 0);"
                  *ngIf="ff.email.hasError('pattern') && !ff.email.hasError('pattern')">
                  Please enter a valid email address
                </mat-error>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.email.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <!-- Country -->
              <div formGroupName="country">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select Country</mat-label>
                  <mat-icon style="
                                transform: translateY(0px);" matPrefix>flag</mat-icon>
                  <mat-select id="countryId" formControlName="countryId" [errorStateMatcher]="matcher">
                    <ng-container *ngIf="countryList.length > 0 ; else countryNodata">
                      <mat-option *ngFor="let u of  countryList" [value]="u.countryId" (click)="getState(u)">
                        {{u.countryName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #countryNodata>
                      <mat-option>
                        {{'No Country List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.country.invalid">
                    Please select <strong>country</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- state -->
              <div formGroupName="state">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select State</mat-label>
                  <mat-icon style="
                                transform: translateY(0px);" matPrefix>holiday_village</mat-icon>
                  <mat-select id="stateId" [errorStateMatcher]="matcher" formControlName="stateId">

                    <ng-container *ngIf="stateList.length > 0; else stateNodata">
                      <mat-option *ngFor="let u of  stateList" [value]="u.stateId" (click)="getCity(u.stateId)">
                        {{u.stateName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #stateNodata>
                      <mat-option>
                        {{'No state List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf=" ff.state.invalid">
                    Please select <strong>state</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- City -->
              <div formGroupName="city">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select City</mat-label>
                  <mat-icon style="transform: translateY(0px);" matPrefix>location_city</mat-icon>
                  <mat-select id="cityId" formControlName="cityId" [errorStateMatcher]="matcher">
                    <ng-container *ngIf="cityList.length > 0; else cityNodata">
                      <mat-option *ngFor="let u of  cityList" [value]="u.cityId">
                        {{u.cityName}}
                      </mat-option>
                    </ng-container>
                    <ng-template #cityNodata>
                      <mat-option>
                        {{'No City List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf=" ff.city.invalid">
                    Please select <strong>city</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- postal code -->
              <div class="phone">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Postal Code</mat-label>
                  <mat-icon style="transform: translateY(0px);" matPrefix>pin</mat-icon>
                  <input matInput type="text" placeholder="Enter Postal Code" maxlength="10" (keypress)="noNumber($event)"
                    formControlName="postalCode" [errorStateMatcher]="matcher" />
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.phoneNumber.hasError('required')">Please Enter
                    <strong>postal code</strong>
                  </mat-error>
                  <!-- <mat-error *ngIf="f.phoneNumber.hasError('minlength')"> Enter <strong> Valid Number</strong></mat-error> -->
                </mat-form-field>
              </div>
              <!-- address -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>&nbsp; Address </mat-label>
                <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>home</mat-icon> -->
                <textarea matInput type="text" formControlName="address" placeholder="Enter Address" rows="10"
                  [errorStateMatcher]="matcher"></textarea>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.address.hasError('required')">
                  Please enter <strong>address</strong>
                </mat-error>
              </mat-form-field>


              <!-- mobile number  -->
              <div class="phone">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-icon style="transform: translateY(0px);" matPrefix>phone</mat-icon>
                  <div style="position: absolute;">
                    {{'+' +selectedCountry_Code}}
                  </div>
                  <input matInput type="text" placeholder="Enter Contact Number" maxlength="10" (keypress)="noNumber($event)"
                    style="padding: 0px 0 0 33px ;" [disabled]='true' formControlName="phoneNumber"
                    [errorStateMatcher]="matcher" />
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.phoneNumber.hasError('required')">Please Enter
                    <strong>contact number</strong>
                  </mat-error>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.phoneNumber.hasError('minlength')"> Enter <strong>
                      valid Number</strong></mat-error>
                </mat-form-field>
              </div>

              <!-- authentication type -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Select Authentication Type</mat-label>
                <mat-icon style="transform: translateY(0px);" matPrefix>vpn_key</mat-icon>
                <mat-select id="authenticationType" formControlName="authenticationType" [errorStateMatcher]="matcher">
                  <mat-option *ngFor="let u of  ['EMAIL', 'SMS']" [value]="u">
                    {{u}}
                  </mat-option>
                </mat-select>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf="ff.authenticationType.invalid">
                  Please select <strong>Authentication Type</strong>
                </mat-error>
              </mat-form-field>

              <!-- password -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon matPrefix>lock</mat-icon>
                <mat-label>Password</mat-label>
                <input id="password1" type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
                  placeholder="Enter Password" [ngClass]="{ 'is-invalid':ff.password.errors }" matInput
                  autocorrect="off" autocapitalize="off" spellcheck="off">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error style="color: rgb(0, 0, 0);" *ngIf=" ff.password.hasError('required')">
                  Password is <strong>required</strong>
                </mat-error>
                <mat-error style="color: black;" *ngIf="ff.password.hasError('minlength')">
                  Password should be atleast <strong>8 character</strong>
                </mat-error>
              </mat-form-field>



              <!-- User Type -->
              <!-- <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Select User Type</mat-label>
              <mat-icon style="
                              transform: translateY(0px);" matPrefix>invert_colors</mat-icon>
              <mat-select style="color: red" id="usertype" formControlName="userType" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let u of userTypeList" [value]="u.userTypeId">
                  {{u.userTypeName }}
                </mat-option>
              </mat-select>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.userType.hasError('required')">
                Please Select <strong>userType</strong>
              </mat-error>
            </mat-form-field> -->



              <!-- User Type -->
              <!-- <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Select Authentication Type</mat-label>
              <mat-icon style="
                                  transform: translateY(0px);" matPrefix>invert_colors</mat-icon>
              <mat-select style="color: red" id="usertype" formControlName="userType" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let u of  userTypeList" [value]="u.userTypeId">
                  {{u.userTypeName}}
                </mat-option>
              </mat-select>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.userType.hasError('required')">
                Please Select <strong>userType</strong>
              </mat-error>
            </mat-form-field> -->
              <button mat-button matStepperPrevious>Back</button>
              <div align="center">
                <!-- <button mat-stroked-button color="primary" style="width: 30%; margin: 20px 0 0 8px;" mat-button
                type="button" routerLink="/login">Cancel</button> -->
                <button mat-stroked-button style="width: 90%; margin: 20px 10px 0 0px;" mat-button (click)="save()"
                  type="submit">Register</button>
              </div>

            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>

    </ng-template>
  </div>

</div>
