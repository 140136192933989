<div class="login_container">
  <div class="col-md-4">
    <ng-container *ngIf="loading; else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2>Please Wait... Verifying</h2>
      </div>
    </ng-container>

    <ng-template #elseComponent>
      <div class="brand">
        <img src="/assets/jepericon.png" alt="" height="75px" />
        <h4>Verify</h4>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div style="
        color: #3e4244;
        font-size: larger;
        font-weight: 500;
        text-align: center;
    ">
          <p>Please enter Verification code</p>
          <p>Sent On your device</p>
        </div>
        <!-- [textMask]="{mask: mask}" -->
        <div class="form-group">
          <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Enter verification code</mat-label>
            <mat-icon matPrefix>vpn_key</mat-icon>
            <input id="password1" type="number" formControlName="OTP" [errorStateMatcher]="matcher" maxlength="6"
              placeholder="enter code" autocorrect="off" autocapitalize="off" spellcheck="off"
              [ngClass]="{ 'is-invalid': submitted && f.OTP.errors }" matInput>
            <mat-error style="color: black;" *ngIf=" submitted && f.OTP.hasError('required')">
              Please enter <strong>OTP</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div align="end">
          <ng-container *ngIf="isotpCount; else elseCount">
            <countdown [config]="{leftTime: 30, notify: [ 1 ]}" (event)="handleEvent($event)"></countdown>
          </ng-container>
          <ng-template #elseCount>
            <button mat-stroked-button (click)="resentOtp()" type="button">
              Re Send OTP
            </button>
          </ng-template>
        </div>
        <br>
        <div class="form-group">
          <button style="width: 100%;" mat-stroked-button>VERIFY</button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
