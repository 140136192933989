<mat-card class="col-md-8">
   
    <ng-container *ngIf="loading;else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2>Please Wait... Registering!</h2>
      </div>
    </ng-container>
    
    <ng-template #elseComponent>

        <mat-card-title style="font-size: 15px;">
            User
         </mat-card-title>
        <form action="" [formGroup]="UserForm" (ngSubmit)="createuser()">
  
       

            <!-- user name -->
            <mat-form-field appearance="outline" class="example-full-width">
             <mat-label>Full Name</mat-label>
             <mat-icon matPrefix style="
                             transform: translateY(0px);">person</mat-icon>
             <input matInput type="text" formControlName="name" placeholder="name" [errorStateMatcher]="matcher"
               (keypress)="omit_special_char($event) && keyPress($event)">
             <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.name.hasError('required')">
               Please enter <strong>Full Name</strong>
             </mat-error>
           </mat-form-field>
 
 
            <!-- mail id -->
            <mat-form-field class="example-full-width" appearance="outline">
             <mat-label>User Mail-Id</mat-label>
             <mat-icon style="
                           transform: translateY(0px);" matPrefix>email</mat-icon>
             <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
               [errorStateMatcher]="matcher" />
             <mat-error style="color: rgb(0, 0, 0);"
               *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
               Please enter a valid email address
             </mat-error>
             <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.email.hasError('required')">
               Email is <strong>required</strong>
             </mat-error>
           </mat-form-field>
 
            <!-- mobile number  -->
            
             <mat-form-field class="example-full-width" appearance="outline">
               <mat-label>phoneNumber</mat-label>
               <mat-icon style="transform: translateY(0px);" matPrefix>phone</mat-icon>
                 <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
                 style="padding: 0px 0 0 3px ;" [disabled]='true' formControlName="phoneNumber"
                 [errorStateMatcher]="matcher" />
                 
                 <!-- <mat-error style="color: rgb(0, 0, 0);"
                 *ngIf="f.phoneNumber.hasError('pattern') && !f.phoneNumber.hasError('required')">
                 Please, Enter 10 digit Mobile Number.
               </mat-error> -->
               <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.phoneNumber.hasError('required')">Please Enter
                 <strong>Contact</strong>
               </mat-error>
                             <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.phoneNumber.hasError('minlength')"> Enter <strong>
                   Valid
                   Number</strong></mat-error>
             </mat-form-field>
           
 
                 <!-- password -->
             <mat-form-field class="example-full-width" appearance="outline">
                 <mat-icon matPrefix>lock</mat-icon>
                 <mat-label>Password</mat-label>
                 <input id="password1" type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
                   placeholder="enter password" [ngClass]="{ 'is-invalid':f.password.errors }" matInput showHideInput>
                 <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                   [attr.aria-pressed]="hide">
                   <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                 </button>
                 <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.password.hasError('required')">
                   Password is <strong>required</strong>
                 </mat-error>
               </mat-form-field>


               <div formGroupName="freetrial">
            
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-icon style="transform: translateY(0px);" matPrefix>phone</mat-icon>
                  <mat-label>Select Trail Period</mat-label>
                  <mat-select id="locationId" formControlName="freetrialId" [errorStateMatcher]="matcher">
                    <ng-container *ngIf="freetriallist.length > 0 ; else countryNodata">
                      <mat-option *ngFor="let u of  freetriallist" [value]="u.freeTrialId" >
                        {{u.noOfDays}}
                      </mat-option>
                    </ng-container>
                    <ng-template #countryNodata>
                      <mat-option>
                        {{'No freetriallist List Found'}}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                  <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.freetrial.invalid">
                    Please select <strong>freetrial</strong>
                  </mat-error>
                </mat-form-field>
              </div>
           
  <br>
  <div class="row">
    <div class="col-sm-6"><button mat-stroked-button  [routerLink]="['/user-induvar']" color="warn" style="width: 100%;" type="button" mat-button
      mat-dialog-close>CANCEL</button></div>
      <div class="col-sm-6">
    <button mat-stroked-button color="warn" style="width: 100%;" type="submit" mat-button>
      CREATE</button>
      </div>
  </div>
</form>

    </ng-template>
    
  </mat-card>
  