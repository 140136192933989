<ng-container *ngIf="isLoading; else elseTemplate">
    <div class="loading">
      <app-loading></app-loading>
      <br>
      <h1>Please Wait Loading...</h1>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="container">
      <h2 mat-dialog-title>Create User</h2>
      <form action="" [formGroup]="useruploadForm">
       
        <button mat-flat-button  >
            <span class="material-icons">
              file_upload
            </span>
           
            <input
            type="file"
            multiple="multiple"
            class="dragAndUploadManual"
            name="myDragElement"
            id="myDragElement"
            accept=".csv"
            formControlName="file"
            (change)="onFileChange($event)"
          />
          </button>
           <br>
        <!-- userType -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select User Type</mat-label>
          <mat-icon style="    color: #6d6b6b;
                      transform: translateY(0px);" matPrefix>account_box</mat-icon>
          <mat-select formControlName="userType" [errorStateMatcher]="matcher">
            <ng-container *ngIf="userTypeList.length > 0;else elseUserType">
              <mat-option *ngFor="let u of userTypeList " [value]="u.userTypeId">
                {{u.userTypeName}}
              </mat-option>
            </ng-container>
  
            <ng-template #elseUserType>
              <mat-option>
                {{"No User List Found"}}
              </mat-option>
            </ng-template>
  
  
          </mat-select>
          <mat-error *ngIf="f.userType.hasError('required')">
            Please Select <strong>User Type</strong>
          </mat-error>
        </mat-form-field>
  
        <div class="form-group" align="center">
          <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button" mat-button
            mat-dialog-close>CANCEL</button>
          <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit"
            [disabled]="useruploadForm.invalid" (click)="uploaduser()">
            CREATE
          </button>
        </div>
      </form>
    </div>
  </ng-template>
  