import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewUserComponent } from '../view-user/view-user.component';


@Component({
  selector: 'app-assign-location',
  templateUrl: './assign-location.component.html',
  styleUrls: ['./assign-location.component.scss']
})
export class AssignLocationComponent implements OnInit {
  @ViewChild('search') searchElementRef!: ElementRef;
  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 24 };
  myModel = ''
  public mask = [/[0-9]/, /\d/, '-', /\d/, /\d/]

  onChangeHour(event: any) {
    console.log('event', event);
  }

  matcher = new MyErrorStateMatcher();
  AssignLocationForm!: FormGroup
  AssignInstantLocationForm! : FormGroup
  endDate_disable: boolean = true;
  min: any;
  someDate: Date = new Date;
  end_time: Date = new Date;
  showmap : any;
  showlocation :any;
  showmaps :any;
  hidemap :any;
  time = { hour: 13, minute: 30 };
  meridian = true;

  locationList  :any = [];
  userList : any = [];
  title: string = 'AGM project';
  latitude: any;
  longitude: any;
  zoom: any;
  address: any;


  constructor(private fb: FormBuilder, public dialog: MatDialog , private auth : AuthService , private Gs : GlobalserviceService) {
    this.someDate.setHours(2);
    this.someDate.setMinutes(30);
     this.showmap = false;
    this.showlocation = true;
     this.hidemap =false;
     this.showmaps = true;
  }

  ngOnInit(): void {

    this.AssignLocationForm = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      timeInterval: ['', [Validators.required, Validators.minLength(4)]],
       location :this.fb.group({
        locationId:  ['', Validators.required]
      }),
      
      status: this.fb.group({
        statusId: 1
      })
    })


    this.AssignInstantLocationForm = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      timeInterval: ['', [Validators.required, Validators.minLength(4)]],
      AddressName :  ['', [Validators.required , Validators.pattern(/^(\w*\s*[\#\-\,\/\.\(\)\&]*)+/)]],
      address: this.fb.group(
        {
          addressName: ['', Validators.required],
          latitude: ['', Validators.required],
          longitude: ['', Validators.required],
        }
      ),
      
      status: this.fb.group({
        statusId: 1
      })
    })

    let user = this.auth.getLocalStorageData()
    console.log(user);

   this.Gs.get_location(user.userId).subscribe((a: any) => {
           this.locationList = a;
   })
         

   this.Gs.get_primary_users_user(user.userId).subscribe((a: any) => {  
    console.log(a)
        this.userList = a;
  })

  this.setCurrentLocation();
   
  }
  get f() {
    return this.AssignLocationForm.controls;
  }

  get ff() {
    return this.AssignInstantLocationForm.controls;
  }


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }


  private formPatchValues() {
    this.AssignLocationForm.patchValue({
      timeInterval: '08-00'
    })
  }

  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false
  }

  change(a: any) {
    console.log(a)
  }
  change_endTime(a: any) {
    console.log(a)

  }


  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.zoom = 15;
  //     });
  //   }
  // }

  assignLocation() {
    console.log(this.AssignLocationForm.value)
    let x = this.AssignLocationForm.value


    if (this.AssignLocationForm.invalid   ) {
      return
    } 

    let final_data = {
      ...x,
      map : false
   }

    const dialogRef = this.dialog.open(ViewUserComponent, {
      width: '750px',
      data: final_data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
          
      console.log(result);
      
    });

  }

  assigninstantLocation()
  {
    console.log(this.AssignInstantLocationForm.value)
    let x = this.AssignInstantLocationForm.value


    if (this.AssignInstantLocationForm.invalid   ) {
      return
    } 


      let final_data = {
         ...x,
         map : true
      }
      
    const dialogRef = this.dialog.open(ViewUserComponent, {
      width: '750px',
      data: final_data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
          
      console.log(result);
      
    });
  }
  Showmap()
  {
    
    this.showmap = true;
    this.showlocation = false;
     this.hidemap = true;
     this.showmaps = false;
  }

  Hidemap()
  {
    this.showmap = false;
    this.showlocation = true;
     this.hidemap = false;
     this.showmaps = true;
  }


  
  markerDragEnd(a: any) {
    console.log(a.coords)
    this.latitude = a.coords.lat
    this.longitude = a.coords.lng
    let fromvalue  = this.AssignInstantLocationForm.value;
    console.log("formvalue" + fromvalue.AddressName);
    let x =  this.AssignInstantLocationForm.get('address')?.patchValue({
    addressName : fromvalue.AddressName,
    latitude: a.coords.lat,
    longitude: a.coords.lng
  })
          console.log(this.AssignInstantLocationForm.value)
   
    
   console.log(x)
  
  }
  mapClicked(c: any) {
    console.log(c)
    let a = c.coords
    this.latitude = a.lat
    this.longitude = a.lng
    // let x = this.AssignInstantLocationForm.get('address')?.patchValue({
    //   addressName: 'address',
    //   latitude: a.lat,
    //   longitude: a.lng,


    // })
  // console.log(x)
  }

}
