import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agmgeofenace',
  templateUrl: './agmgeofenace.component.html',
  styleUrls: ['./agmgeofenace.component.scss']
})
export class AgmgeofenaceComponent implements OnInit {

  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['polygon']
    },
    polygonOptions: {
      draggable: true,
      editable: true
    },
    drawingMode: 'polygon'
  };


  constructor() { }

  ngOnInit(): void {
  }

  polygonCreated(event :any) {
      console.log(event.getPaths().getArray()[0]);
      const vertices =event.getPaths().getArray()[0];
    vertices.getArray().forEach(function(xy:any, i:any) {
      // console.log(xy);
      let latLng = {
        lat: xy.lat(),
        lng: xy.lng()
      };
       console.log(latLng);
    });
  }
}
