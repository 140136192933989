import { Component, OnInit ,Inject} from '@angular/core';
import { MouseEvent } from '@agm/core';
declare var google: any;
import { AgmPolygon, PolygonManager , MapsAPILoader } from '@agm/core';
import { MatDialog , MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-user-location',
  templateUrl: './user-location.component.html',
  styleUrls: ['./user-location.component.scss']
})
export class UserLocationComponent implements OnInit {
  latitude: any;
  longitude: any;
  zoom: any;
  baselat:any;
  baselong:any;
  latlondData:any;
  polyline :any = [];
  address: string;
  reportList: any=[];
  pointsData: any=[];
  lat: any;
  long: any;
  polygon: any;
  map: google.maps.Map
  managerOptions :any = {};
  mapready: boolean = false;
  mapData:any = [];
  points: any=[];
  // infoWindow:boolean=false;
  
  // polyline: { latitude: number; longitude: number; }[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public mapsApiLoader: MapsAPILoader,
  public dialog: MatDialog) { 
    { 

      // this.mapsApiLoader.load().then(() => { 
      //   this.data.pointsArray.forEach(element => {
      //     console.log(element);
      //     if(element.geofence.geofenacePoints!=null)
      //     {
      //       const data1 = element.geofence.geofenacePoints;       
      //       data1.split(')(').forEach((item:any ,  i:any ) => {
      //         const coordinates = item.replace('(', '').replace(')', '').split(',');
      //         if(i == 0)
      //         {
      //            this.lat =  parseFloat(coordinates[0]);
      //            this.long = parseFloat(coordinates[1]);
      //         }
      //         const lat = parseFloat(coordinates[0]);
      //         const lng = parseFloat(coordinates[1]);
               
      //         const obj = { lat, lng };
      //         this.pointsData.push(obj);
      //         console.log(this.pointsData);
      //         this.drawPolygon(this.pointsData);
      //       });
           
      //     }
      //  });
      // });
    }
  }

  ngOnInit(): void {
    console.log("Data:",this.data);
    this.reportList=this.data.latLongArray;
    console.log(this.reportList);
    this.latitude = this.reportList[0].latitude;
    this.longitude = this.reportList[0].longitude;
    console.log(this.data.pointsArray);
    // this.onMapReady(this.map);
    // for (let i = 0; i < this.data.pointsArray.length; i++) {
      console.log(this.data.pointsArray.length);
      this.data.pointsArray.forEach(element => {
        console.log(element);
        if(element.geofence.geofenacePoints!=null&&element.status.statusId==1)
        {
          console.log("Active Status");
          this.pointsData=[];
          const data1 = element.geofence.geofenacePoints;       
          data1.split(')(').forEach((item:any ,  i:any ) => {
            const coordinates = item.replace('(', '').replace(')', '').split(',');
            if(i == 0)
            {
               this.lat =  parseFloat(coordinates[0]);
               this.long = parseFloat(coordinates[1]);
            }
            const lat = parseFloat(coordinates[0]);
            const lng = parseFloat(coordinates[1]);
             
            const obj = { lat, lng };
            this.pointsData.push(obj);
            console.log(this.pointsData);
            // this.points=this.pointsData;
            // console.log(this.points);
          });
           // this.onMapReady(this.map)
           this.points.push(this.pointsData);
           console.log(this.points);
        }
       
     });
    
    // }
    console.log("fial Data",this.points);
     this.zoom = 8;
     const result = Array.isArray(this.data);
    }
    

   

  // onMapReady(map: google.maps.Map) {
  //   this.map = map
  //    this.mapready = true;
  // }



  // drawPolygon(pointsData)
  //  {
  //   console.log(pointsData);
  //   const paths=this.pointsData;
  //   console.log(paths);
  //   console.log(this.map);
  //    const polygon = new google.maps.Polygon({
  //     paths: paths,
  //     editable: false,
  //     draggable: false,
  //   })
  //   console.log(polygon);
  //    polygon.setMap(this.map)
  //    console.log(polygon);
  // }

    remove()
    {
      console.log("remove");
      this.dialog.closeAll();
    }
      
     
    onMouseOver(infoWindow:any, $event: MouseEvent) {
            //  this.address=this.data.address;
             console.log("open address",this.address);
            infoWindow.open();
        }
    
    onMouseOut(infoWindow:any, $event: MouseEvent) {
      console.log("close address",this.address);
            infoWindow.close();
        }
    
  }
  

