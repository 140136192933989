import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { UserService } from '../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-settingview',
  templateUrl: './settingview.component.html',
  styleUrls: ['./settingview.component.scss']
})
export class SettingviewComponent implements OnInit {
  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber','fromDate','toDate','action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false
  id:any;
  isLoading: boolean = false
   dontnottrackdata : any = {};
  @ViewChild(MatPaginator) paginator:any;

  constructor(public dialog: MatDialog, private gs: UserService,
    private Gs: GlobalserviceService, private auth: AuthService,  private router: Router,
    private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any
   ) {

      
  }

  ngOnInit() {
    // alert("page called")
 
            
    this.Gs.get_dont_track_byid(this.data.id).subscribe((dontnottrack: any) => {
      console.log(dontnottrack)
          this.dontnottrackdata = dontnottrack;
    
        })
   



  }

  approve(e:any){
    
    console.log(e)
    e['approvalStatus'] ={
      approvalStatusId:2
    }
    this.Gs.put_dont_track(e.trackId,e).subscribe(
      
      (d:any)=>{
       window.location.reload();
        console.log(d)
      },
      err=>{
        console.log(err)
      }
    )
  }
  denied(e:any){
    e['approvalStatus'] ={
      approvalStatusId:3
    }
    this.Gs.put_dont_track(e.trackId,e).subscribe(
      (d:any)=>{
       window.location.reload();
        console.log(d)
      },
      err=>{
        console.log(err)
      }
    )

  }
}