
<!-- <div class="material-icons" id="cancel" mat-raised-button mat-dialog-close>cancel</div>
<div class="title"><h1 style="text-align: center; font-size: 30px;font-weight: 500;">View Details</h1></div> -->
<div class="row">
    <div class="col-sm-11"><h1 style=" font-size: 30px;font-weight: 500;">View Details:</h1></div>
    <div class="col-sm-1"><span class="material-icons" id="cancel" mat-raised-button mat-dialog-close>cancel</span></div>
</div>

<hr style="border: 2px solid rgba(143, 139, 139, 0.692);">
<div style="margin:0px  25px 25px 50px;">
    <div class="row">
        <div class="col-sm-6 content1" ><span class="material-icons">
         badge
         </span>Name</div>
        <div class="col-sm-6 content2" >{{dontnottrackdata.user.name}}</div>
    </div>
    <div class="row">
     <div class="col-sm-6 content1"><span class="material-icons">
         call
         </span>Phone Number</div>
     <div class="col-sm-6 content2">{{dontnottrackdata.user.phoneNumber}}</div>
 </div>
 <div class="row">
     <div class="col-sm-6 content1"><span class="material-icons">
         email
         </span>Email Address</div>
     <div class="col-sm-6 content2">{{dontnottrackdata.user.email}}</div>
 </div>
 <div class="row">
     <div class="col-sm-6 content1"><span class="material-icons">
        event
         </span>From-Date</div>
     <div class="col-sm-6 content2">{{dontnottrackdata.fromDate}}</div>
 </div>
 <div class="row">
    <div class="col-sm-6 content1"><span class="material-icons">
        today
        </span>To-Date</div>
    <div class="col-sm-6 content2">{{dontnottrackdata.toDate}}</div>
</div>
</div>
<hr style="border: 2px solid rgba(143, 139, 139, 0.692);">
<div class="row">
    <div class="col-sm-9">
       </div>
    <div class="col-sm-3">  
        <button style="width:45%;margin-right:10px;background-color: red;color:white;" mat-raised-button  (click) = "denied(dontnottrackdata)"  >Denied</button>
        <button style="width:45%;background-color:green;color:white" mat-raised-button  (click) = "approve(dontnottrackdata)" >Approve</button>
    </div>
</div>

