<ng-container *ngIf="isLoading; else elseTemplate">
    <div class="loading">
      <app-loading></app-loading>
      <br>
      <h1>Please Wait Loading...</h1>
    </div>
  </ng-container>
  
  <ng-template #elseTemplate>
    <div class="container">
  
      <div class="mat-elevation-z8">
    
        <div class="header_action">
         
          <!-- <button mat-flat-button>
          <span class="sign_up"  [routerLink]="['/add-user']"> <mat-icon matPrefix style="
            transform: translateY(8px);">add</mat-icon>
            &nbsp; Create User 
          </span>
          </button> -->
          <!-- <mat-menu #menu="matMenu"  yPosition="above" hasBackdrop="true">
       
            <button mat-menu-item [routerLink]="['/add-user']">User</button>             
          </mat-menu> -->

     
         
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="search name/ email/ phone number" #input>
          </mat-form-field>
        </div>
        <!-- <div class="filter">
    
        </div> -->
        <table mat-table [dataSource]="dataSource">
    
          <!-- Position Column -->
          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
            <td mat-cell *matCellDef="let element; let i =index">  {{ currentPageOffset + i + 1 }}  </td>
          </ng-container>
          
    
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> <b>NAME</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.user.name}} </td>
          </ng-container>

 
    
    
    
          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> <b>EMAIL ID</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.user.email}} </td>
          </ng-container>
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> <b>PHONE NUMBER</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.user.phoneNumber}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef> <b>FromDate</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.fromDate | date}} </td>
          </ng-container>
          <ng-container matColumnDef="toDate">
            <th mat-header-cell *matHeaderCellDef> <b>ToDate</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.toDate | date }} </td>
          </ng-container>
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="approvalStatus">
            <th mat-header-cell *matHeaderCellDef> <b>Status</b> </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Example icon-button with a menu"  >
                <mat-icon class="success" *ngIf="element.approvalStatus.approvalStatusName == 'Accepted'">check_circle
                </mat-icon>
                <mat-icon class="danger" *ngIf="element.approvalStatus.approvalStatusName == 'Denied\r\n'">cancel
                </mat-icon>
                <mat-icon class="war" *ngIf="element.approvalStatus.approvalStatusName == 'Pending'">history
                </mat-icon>
                
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> <b>Action</b> </th>
            <td mat-cell *matCellDef="let element">  <span class="material-icons" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              more_vert
            </span>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="approve(element)">
                <mat-icon>streetview</mat-icon>
                <span>Approve</span>
              </button>
              <button mat-menu-item (click)="denied(element)">
                <mat-icon>date_range</mat-icon>
                <span>Denied</span>
              </button>
            </mat-menu> </td>
          </ng-container>
      
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" align="center">
              <div class="alert alert-secondary" style="width: 177%;">
                <h3>No Request Found {{input.value}}</h3>
              </div>
            <td>
          </tr>
        </table>
    
    
     
  <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    
      </div>
    </div>
    </ng-template>
  
  