<mat-card class="col-md-10">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{user_data.name | uppercase}}</mat-card-title>
    <mat-card-subtitle>{{user_data.email}}</mat-card-subtitle>
    <mat-card-subtitle>{{user_data.phoneNumber}}</mat-card-subtitle>
  </mat-card-header>


  

  <div align="start">
    <mat-label>Address : </mat-label> <mat-label>{{workplacelist[0].locationDetails.address}}</mat-label>
    
  </div>

  <form action="" [formGroup]="showLogsForm" (ngSubmit)="show()">
    <!-- <div class="col-md-4">
        <mat-form-field class="full-width" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="search name/ email/ phone number" #input>
        </mat-form-field>
      </div> -->

    <div align="end">
      <mat-form-field style="width: 100px;">
        <mat-label>&nbsp; From Date</mat-label>
        <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
        <input matInput (click)="picker.open()" (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher"
          formControlName="fromDate" [matDatepicker]="picker" placeholder="select start date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
        <mat-error *ngIf="f.fromDate.hasError('required')">
          Please select <strong>From Date</strong>
        </mat-error>
      </mat-form-field>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <!-- todate -->
      <mat-form-field style="width: 100px;">
        <mat-label>&nbsp; To Date</mat-label>
        <!-- <mat-icon style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon> -->
        <input matInput (click)="a.open()" [min]="min" [errorStateMatcher]="matcher" formControlName="toDate"
          [matDatepicker]="a" placeholder="select end date" placeholder="select end date"
          [disabled]="endDate_disable" />
        <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
        <mat-datepicker #a touchUi></mat-datepicker>
        <mat-error *ngIf="f.toDate.hasError('required')">
          Please select <strong>To Date</strong>
        </mat-error>
      </mat-form-field>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <button mat-raised-button color="primary" >Show Logs</button>
    </div>

  </form>

  <mat-form-field>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="search date / time / address" #input >
  </mat-form-field>


  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> <b>SL.NO</b> </th>
      <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> <b>FROM DATE</b> </th>
      <td mat-cell *matCellDef="let element"> {{element.date | date: 'd/MM/YYYY'}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef> <b>TO DATE</b> </th>
      <td mat-cell *matCellDef="let element"> {{element.time}} </td>
    </ng-container>

     <!-- Symbol Column -->
     <ng-container matColumnDef="latitude">
      <th mat-header-cell *matHeaderCellDef> LATITUDE </th>
      <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
    </ng-container>

     <!-- Symbol Column -->
     <ng-container matColumnDef="longitude">
      <th mat-header-cell *matHeaderCellDef> LONGITUDE </th>
      <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> ADDRESS </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20,30,50,100,150,200,500]" showFirstLastButtons></mat-paginator>
</mat-card>
