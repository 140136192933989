<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading">
    <app-loading></app-loading>
    <br>
    <h1>Please Wait Loading...</h1>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <h1 mat-dialog-title>Selected User List</h1>
  <div class="example-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b>VIEW LOG</b>
        </th>
        <td mat-cell  *matCellDef="let element;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-icon-button aria-label="Example icon button with a open in new tab icon"
            (click)="viewLogs(element)">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element; let x = index"> {{(x+1) + ') ' + element.name}} </td>
      </ng-container>



      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> <b>EMAIL ID</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef> <b>PHONE NUMBER</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef> <b>USER TYPE</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.userType[0].userTypeName}} </td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow align="center">
        <td class="mat-cell" colspan="4">
          <h2>N User List Found</h2>
        <td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
  </div>
  <div class="form-group" align="center">
    <button mat-stroked-button color="warn" style="width: 80%; margin: 0 0 0 8px;" type="button" mat-button
      mat-dialog-close>CANCEL</button>
  </div>
</ng-template>
