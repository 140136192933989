import { Component, OnInit } from '@angular/core';
import { MessagingService } from './service/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
}) 
export class AppComponent implements OnInit {
  title = 'Tracker-asdasdapp';
  myModel: any; 
  message :any;
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  constructor(private messagingService: MessagingService) { }
  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage() 
    this.messagingService.backgroundmessage()
    this.message = this.messagingService.currentMessage
      console.log("message");
      console.log(this.message);

      // navigator.serviceWorker.addEventListener('message', (event) => {
      //        alert("navigator service worker ")
      //  // this.messagingService.processMessage(event)
    
      //   });

        // if (window.addEventListener) {
        //   window.addEventListener("message", this.messagingService.processMessage.bind(this), false);
        // } else {
        //    (<any>window).attachEvent("onmessage", this.messagingService.processMessage.bind(this));
        // }
     
   }

}
