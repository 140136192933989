<div class="login_container">
  <div class="col-md-5">
    <ng-container *ngIf="loading; else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2 >Please Wait... Authenticating</h2>
      </div>
    </ng-container>

    <ng-template #elseComponent>
      <div class="brand">
        <img src="/assets/jepericon.png" alt="" height="75px" />
        <h4>LOGIN</h4>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="form-group">
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>Mail-Id</mat-label>
              <mat-icon matPrefix>account_box</mat-icon>
              <input id="password1" type="text" formControlName="username" [errorStateMatcher]="matcher"
                autocorrect="off" autocapitalize="off" spellcheck="off" placeholder="ex@jesperapps.com"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" matInput>
              <mat-error *ngIf="submitted && f.username.hasError('pattern') && !f.username.hasError('required')">
                Please enter a valid email address
              </mat-error>
              <mat-error style="color: black;" *ngIf=" submitted && f.username.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="fill" style="width: 100%" *ngIf="!email">
              <mat-label>Enter your mobile number</mat-label>
              <input matInput type="number" autocomplete="doNotAutoComplete" class="example-right-align" required>
              <span matPrefix>
                <mat-icon>phone</mat-icon>&nbsp;
              </span>
              <span matSuffix>
                <mat-icon (click)="change_field()">flip_camera_android</mat-icon>&nbsp;
              </span>
            </mat-form-field> -->
          </div>

          <div class="form-group">
            <mat-form-field style="width: 100%" appearance="outline" class="md-10">
              <mat-icon matPrefix>lock</mat-icon>
              <mat-label>Password</mat-label>
              <input id="password1" type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
                placeholder="enter password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput
                autocorrect="off" autocapitalize="off" spellcheck="off"
                >
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error style="color: black;" *ngIf="submitted && f.password.hasError('required')">
                Password is <strong>required</strong>
              </mat-error>
         
            </mat-form-field>
          </div>

          <div class="form-group">
            <button style="width: 100%;    font-size: larger;" mat-stroked-button>LOGIN</button>
          </div>

          <div class="sign_up">
              <h5>Don't have account?<span [matMenuTriggerFor]="menu" class="start">&nbsp; Signup</span></h5> 
              <mat-menu #menu="matMenu" style="background: rgba(53, 50, 50, 0.227);" yPosition="above" hasBackdrop="true">
                <button mat-menu-item [routerLink]="['/sign-up']">Organization</button>
                <button mat-menu-item [routerLink]="['/register']">User</button>             
              </mat-menu>
              <span [routerLink]="['/forget-password']"   class="forget">&nbsp; Forget Password?</span>

          </div>
  
        </div>
      </form>
    </ng-template>
  </div>
</div>
