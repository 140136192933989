import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  userForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false
  userdata :any;
  isOrg :any;
  userTypeList: any[] = []

  constructor(private fb: FormBuilder, public dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any , public auth : AuthService , public gs :GlobalserviceService) { }

  ngOnInit(): void {

    console.log(this.data)
     

    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true
    } else {
      console.log("else")
      this.isOrg = false
    }


    this.userForm = this.fb.group({
      name: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      userType: ['', Validators.required]
    })


       console.log("usertype" +  this.data.userType[0].userTypeId);

    this.userForm.patchValue({
      name:this.data.name,
      mobileNumber:this.data.phoneNumber,
      email: this.data.email,
     userType : this.data.userType[0].userTypeId
       

    })


    this.gs.get_usertype().subscribe(a => {
      console.log(a)
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        this.userTypeList = primary_user
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        this.userTypeList = primary_user
      }
    })
  }




  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() {
    return this.userForm.controls;
  }

  update_user() {

    if(this.userForm.invalid){
      return
    }
     
    let x = this.userForm.value
    console.log("x form data")
    console.log(x)
    
    //this.isLoading = true
    
       console.log("this.data.password"  + this.data.password);
     
    let org = this.auth.getLocal_Org()
    let user = this.auth.getLocalStorageData()

    console.warn(this.isOrg)

    if (this.isOrg) {
      let z = {
        userId : this.data.userId,
        name: x.name,
        phoneNumber: x.mobileNumber,
        email: x.email,
        password:  this.data.password,
        authenticationType: "null",
        createdByUser: user.userId,
        address: org.address,
        postalCode: org.postalCode,
        alternatePhoneNumber: x.mobileNumber,
        organization: {
          organizationId: org.organizationId
        },

        userType: [
          {
            userTypeId: x.userType
          }
        ],
        // states: {
        //   stateId: org.state.stateId
        // },
        // country: {
        //   countryId: org.country.countryId
        // },
        // city: {
        //   cityId: org.city.cityId
        // }
      }
      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Update_Org_User(z, this.data.userId).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          'User Updated Successfully',
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )
    } else {
      let z = {
        userId : this.data.userId,
        name: x.name,
        phoneNumber: x.mobileNumber,
        email: x.email,
        password:  this.data.password,
        authenticationType: "null",
        createdByUser: user.userId,
        // country: {
        //   countryId: user.country.countryId
        // },
        // states: {
        //   stateId: user.states.stateId
        // },
        // city: {
        //   cityId: user.city.cityId
        // },
        userType: [
          {
            userTypeId: x.userType
          }
        ],
        postalCode: user.postalCode,
        address: user.address,
      }

      console.log("user with individual", z)
      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Update_Org_User(z , this.data.userId).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          "User Created Successfully",
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )

    }
  }
}
