import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit {
  countryList: any = []
  stateList: any = []
  cityList: any = []
  industrytypeList: any = []
  freetrialobj : any = [];

  selectedCountry_Code: any = ""
  selectedCountry_Code1: any = ''

  matcher = new MyErrorStateMatcher();
  signUpForm!: FormGroup;
  Countries = ["india"]
  isLinear = true;
  hide = true;
  userRegisterForm!: FormGroup;
  secondFormGroup!: FormGroup;
  loading = false
  public mask = [/[1-9]/, /\d/, /\d/, /\d/]


  constructor(private fb: FormBuilder, private gs: GlobalserviceService, private router: Router) { }

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      organizationName: ['', Validators.required],
      industryType: this.fb.group({
        industryTypeId: [, Validators.required],
      }),
      contactEmail: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      fiscalYearStart: ['',[Validators.required, Validators.pattern(/(([0-9])|([0-2][0-9])|([3][0-1]))\-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)$/)]],
      gstNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/), Validators.minLength(15)]],
      country: this.fb.group(
        {
          countryId: [, Validators.required]
        }
      ),
      states: this.fb.group({
        stateId: ['', Validators.required],
      }),
      city: this.fb.group({
        cityId: ['', Validators.required]
      }),
      postalCode: ['', [Validators.required , Validators.pattern(/^[0-9 \-]+$/)]],
      address: ['', Validators.required],
      contactCountryCode: [''],
      contactPhoneNumber: ['', Validators.required],
      organizationDomain : ['', [Validators.required  , Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)]],
    })

    this.userRegisterForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      country: this.fb.group(
        {
          countryId: [, Validators.required]
        }
      ),
      state: this.fb.group({
        stateId: ['', Validators.required],
      }),
      city: this.fb.group({
        cityId: ['', Validators.required]
      }),
      postalCode: ['', [Validators.required , Validators.pattern(/^[0-9 \-]+$/)]],
      address: ['', Validators.required],
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      password: ['', [Validators.required, Validators.minLength(8)]],
      authenticationType: ['', Validators.required],
    })



    this.getCountryList()
    this.getIndustryType()
    this.getfreetrail()
  }

  private getfreetrail()
  {
    this.gs.get_freetrial_default().subscribe(a => {
      console.log(a)
      this.freetrialobj = a
      console.log("free trail");
       console.log(this.freetrialobj);
    })
  }
  private getIndustryType() {
    this.gs.getIndustryType().subscribe(a => {
      console.log(a)
      this.industrytypeList = a
    })
  }

  getCountryList() {
    this.gs.getCountry().subscribe((a: any) => {
      console.log(a)
      this.countryList = a;
    })
  }

  getState(a: any) {
    console.log(a)
    this.selectedCountry_Code = a.phoneCode
    this.signUpForm.patchValue({
      contactCountryCode: (a.phoneCode).toString()
    })
    this.gs.getState(a.countryId).subscribe(
      (d: any) => {
        this.stateList = d
      }, err => {
        console.log("state err", err)
      }
    )
  }

  getCity(a: any) {
    console.log("stateId", a)
    this.gs.getCity(a).subscribe(d => {
      console.log(d)
      this.cityList = d
    })
  }

  get f() {
    return this.signUpForm.controls;
  }
  get ff() {
    return this.userRegisterForm.controls;
  }


  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  save() {
    console.log(this.signUpForm.value)
    let x = this.signUpForm.value;
    let y = this.userRegisterForm.value

    if (this.signUpForm.invalid || this.userRegisterForm.invalid) {
      return
    }

    let final_data;

      if(this.freetrialobj[0].freeTrialId != null || this.freetrialobj[0].freeTrialId != undefined)
      {
         final_data = {
          ...x,
          user: [y] ,
          freeTrial : {
            freeTrialId : this.freetrialobj[0].freeTrialId
          }
        }
      }else
      {
        final_data = {
          ...x,
          user: [y] 
        }
      }
   

    console.log(JSON.stringify(final_data))
    this.loading = true
    this.gs.RegisetrOrgUser(final_data).subscribe(
      (d: any) => {
        console.log(d)
        this.loading = false
        switch (this.ff.authenticationType.value) {
          case 'SMS':
            if (d) {
              Swal.fire({
                text: 'Verify OTP',
                icon: 'success',
              })
            }
            this.router.navigate(['/verify-otp', d?.user[0]?.phoneNumber]);
            break;
          case 'EMAIL':
            if (d) {
              Swal.fire({
                text: 'Please Verify Your Email',
                icon: 'success',
              })
            }
            this.router.navigate(['/verify-email', d?.user[0]?.email]);
            break;
          default:
            break;
        }
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }

}
