import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'phoneNumber', 'userType', 'select'];
  dataSource: any = new MatTableDataSource;
  selection: any = new SelectionModel<any>(true, []);

  selectedCount: any = 0
   userlist : any = [];
  isLoading: boolean = false

  constructor(public dialogRef: MatDialogRef<ViewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private Gs: GlobalserviceService, private auth: AuthService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log(this.data);
    let  userdata = this.auth.getLocalStorageData();
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      console.log(id)
      this.Gs.get_user_org(id).subscribe((a: any) => {

           a.map(
          (e: any)=>{
              if(userdata.userType[0].userTypeName == "BUDDY USER")
              {
                
               if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
                 console.log(e)
                  this.userlist.push(e);
               }
              }else {
               if(e.userId !=  userdata.userId  ){
                 console.log(e)
                  this.userlist.push(e);
               }
              }
         
         }
       )
        this.dataSource = new MatTableDataSource(this.userlist);
        console.log(a)
      })
    } else {
      let id = this.auth.getLocalStorageData()?.userId;
      this.Gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
           if(e.userId != id){
           //  console.log(e)
              this.userlist.push(e);
           }
         }
       )
   
        this.dataSource = new MatTableDataSource(this.userlist);
        console.log(a)
      })
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => this.selection.select(row));

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      // console.log(x.selected.length)
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  assign_location() {
    let x = this.data
    let user = this.selection.selected;
    console.log(x, user)
    if (user.length == 0) {
      alert('please select the user')
    }

    let primary_user_Id = this.auth.getLocalStorageData()

    let f_d: any = []

     if(x.map == false)
     {
      user.filter((a: any) => {
            console.log(a)
            let final_data = {
              fromDate: this.dateFormat(x.fromDate),
              toDate: this.dateFormat(x.toDate),
              startTime: this.convertTime12to24(x.startTime),
              endTime: this.convertTime12to24(x.endTime),
              timeInterval: x.timeInterval,
              assignedFromUser: {
                userId: primary_user_Id.userId
              },
              assignedToUser: {
                userId: a.userId
              },
              locationDetails : {
                locationId : x.location.locationId
                },
              status: {
                statusId: 1
              }
            }

            f_d.push(final_data)
          })

          console.log(f_d)

    this.Gs.Assign_Location(f_d).subscribe(
      (d:any) => {
        console.log(d)
        this.isLoading = false
        if (d) {
          Swal.fire({
            text: d.description,
            icon: 'success',
          })
        }
        this.dialog.closeAll()
      },
      err => {
        this.isLoading = false
        console.log(err)
        let x = err.error.description
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.description)
          if (err) {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
     } else {
            
      user.filter((a: any) => {
        console.log(a)
        let final_data = {
          fromDate: this.dateFormat(x.fromDate),
          toDate: this.dateFormat(x.toDate),
          startTime: this.convertTime12to24(x.startTime),
          endTime: this.convertTime12to24(x.endTime),
          timeInterval: x.timeInterval,
          assignedFromUser: {
            userId: primary_user_Id.userId
          },
          assignedToUser: {
            userId: a.userId
          },
          locationDetails : {
            latitude: x.address.latitude,
            longitude :x.address.longitude,
             address: x.AddressName,
              user: {
              userId: primary_user_Id.userId
              }
            },
          status: {
            statusId: 1
          }
        }

        f_d.push(final_data)
      })

        console.log("map data")
      console.log(f_d)

            
      this.Gs.Assign_Instant_Location(f_d).subscribe(
        (d:any) => {
          console.log(d)
          this.isLoading = false
          if (d) {
            Swal.fire({
              text: d.description,
              icon: 'success',
            })
          }
          this.dialog.closeAll()
        },
        err => {
          this.isLoading = false
          console.log(err)
          let x = err.error.description
          if (x == undefined) {
            Swal.fire(
              "Check Net:: Connection :: err"
            )
          } else {
            console.log(err.error.description)
            if (err) {
              Swal.fire({
                text: err.error.description,
                icon: 'error'
              })
            }
          }
        },
        () => {
          console.log('done')
        }
      )
     }
   
   
  }


  dateFormat(date: any) {

    let d = new Date(date)
    d.setDate(d.getDate() + 1);

    let x: any = new Date(d).toISOString()
    let z = x.substring(0, 10);
    console.log(z)

    return z
  }

  convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:00`;
  }

}
