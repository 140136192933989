import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: ['./test-form.component.scss']
})
export class TestFormComponent implements OnInit {

  user_form!: FormGroup;
  /* form group  {} */
  /* form Control "" [] {} */
  /* form Array [] */

  constructor() {

  }

  ngOnInit(): void {


  }

}
