<div class="register_container">
  <div class="col-md-8">
    <mat-card-title>
      Register
    </mat-card-title>

    <ng-container *ngIf="loading; else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2>Please Wait... Registering!</h2>
      </div>
    </ng-container>
    <ng-template #elseComponent>
      <form [formGroup]="userRegisterForm" (ngSubmit)="onSubmit()">
        <div class="overflow_">

          <!-- user name -->
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Full Name</mat-label>
            <mat-icon matPrefix style="
                            transform: translateY(0px);">person</mat-icon>
            <input matInput type="text" formControlName="name" placeholder="name" [errorStateMatcher]="matcher"
              (keypress)="omit_special_char($event) && keyPress($event)">
            <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.name.hasError('required')">
              Please enter <strong>Full Name</strong>
            </mat-error>
          </mat-form-field>

          <!-- mail id -->
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Mail-Id</mat-label>
            <mat-icon style="
                          transform: translateY(0px);" matPrefix>email</mat-icon>
            <input matInput type="text" formControlName="email" placeholder="ex@jesperapps.com"
              [errorStateMatcher]="matcher" />
            <mat-error style="color: rgb(0, 0, 0);"
              *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.email.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <!-- Country -->
          <div formGroupName="country">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Select Country</mat-label>
              <mat-icon style="
                              transform: translateY(0px);" matPrefix>flag</mat-icon>
              <mat-select id="countryId" formControlName="countryId" [errorStateMatcher]="matcher">
                <ng-container *ngIf="countryList.length > 0 ; else countryNodata">
                  <mat-option *ngFor="let u of  countryList" [value]="u.countryId" (click)="getState(u)">
                    {{u.countryName}}
                  </mat-option>
                </ng-container>
                <ng-template #countryNodata>
                  <mat-option>
                    {{'No Country List Found'}}
                  </mat-option>
                </ng-template>
              </mat-select>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.country.invalid">
                Please Select <strong>Country</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- state -->
          <div formGroupName="states">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Select State</mat-label>
              <mat-icon style="
                              transform: translateY(0px);" matPrefix>holiday_village</mat-icon>
              <mat-select id="stateId" [errorStateMatcher]="matcher" formControlName="stateId">

                <ng-container *ngIf="stateList.length > 0; else stateNodata">
                  <mat-option *ngFor="let u of  stateList" [value]="u.stateId" (click)="getCity(u.stateId)">
                    {{u.stateName}}
                  </mat-option>
                </ng-container>
                <ng-template #stateNodata>
                  <mat-option>
                    {{'No state List Found'}}
                  </mat-option>
                </ng-template>
              </mat-select>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.states.invalid">
                Please Select <strong>State</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- City -->
          <div formGroupName="city">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Select City</mat-label>
              <mat-icon style="
                                      transform: translateY(0px);" matPrefix>location_city</mat-icon>
              <mat-select id="cityId" formControlName="cityId" [errorStateMatcher]="matcher">
                <ng-container *ngIf="cityList.length > 0; else cityNodata">
                  <mat-option *ngFor="let u of  cityList" [value]="u.cityId">
                    {{u.cityName}}
                  </mat-option>
                </ng-container>
                <ng-template #cityNodata>
                  <mat-option>
                    {{'No City List Found'}}
                  </mat-option>
                </ng-template>
              </mat-select>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.city.invalid">
                Please Select <strong>City</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- postal code -->
          <div class="phone">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Postal Code</mat-label>
              <mat-icon style="transform: translateY(0px);" matPrefix>pin</mat-icon>
              <input matInput type="text" placeholder="postalCode" maxlength="10" (keypress)="noNumber($event)"
                formControlName="postalCode" [errorStateMatcher]="matcher" />
              <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.phoneNumber.hasError('required')">Please Enter
                <strong>Postal Code</strong>
              </mat-error>
              <!-- <mat-error *ngIf="f.phoneNumber.hasError('minlength')"> Enter <strong> Valid Number</strong></mat-error> -->
            </mat-form-field>
          </div>
          <!-- address -->
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>&nbsp; Address </mat-label>
            <!-- <mat-icon style=" transform: translateY(0px)" matPrefix>home</mat-icon> -->
            <textarea matInput type="text" formControlName="address" placeholder="Enter your address" rows="10"
              [errorStateMatcher]="matcher"></textarea>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.address.hasError('required')">
              Please enter <strong>Address</strong>
            </mat-error>
          </mat-form-field>


          <!-- mobile number  -->
          <div class="phone">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon style="transform: translateY(0px);" matPrefix>phone</mat-icon>
              <div style="position: absolute;">
                {{selectedCountry_Code}}
              </div>
              <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
                style="padding: 0px 0 0 33px ;"  formControlName="phoneNumber"
                [errorStateMatcher]="matcher" />
              <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.phoneNumber.hasError('required')">Please Enter
                <strong>Contact</strong>
              </mat-error>
              <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.phoneNumber.hasError('minlength')"> Enter <strong> Valid
                  Number</strong></mat-error>
            </mat-form-field>
          </div>

          <!-- authentication type -->
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Select Authentication Type</mat-label>
            <mat-icon style="
                              transform: translateY(0px);" matPrefix>vpn_key</mat-icon>
            <mat-select id="authenticationType" formControlName="authenticationType" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let u of  ['EMAIL', 'SMS']" [value]="u">
                {{u}}
              </mat-option>
            </mat-select>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf="f.authenticationType.invalid">
              Please Select <strong>Authentication Type</strong>
            </mat-error>
          </mat-form-field>

          <!-- password -->
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matPrefix>lock</mat-icon>
            <mat-label>Password</mat-label>
            <input  type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
              placeholder="enter password" [ngClass]="{ 'is-invalid':f.password.errors }" matInput
              autocorrect="off" autocapitalize="off" spellcheck="off"
              >
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.password.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error style="color: black;" *ngIf="f.password.hasError('minlength')">
              Password should be atleast <strong>8 character</strong>
            </mat-error>
          </mat-form-field>



          <!-- User Type -->
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Select User Type</mat-label>
            <mat-icon style="
                            transform: translateY(0px);" matPrefix>invert_colors</mat-icon>
            <mat-select style="color: red" id="usertype" formControlName="userType" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let u of userTypeList" [value]="u.userTypeId">
                {{u.userTypeName }}
              </mat-option>
            </mat-select>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.userType.hasError('required')">
              Please Select <strong>userType</strong>
            </mat-error>
          </mat-form-field> -->



          <!-- User Type -->
          <!-- <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Select Authentication Type</mat-label>
            <mat-icon style="
                                transform: translateY(0px);" matPrefix>invert_colors</mat-icon>
            <mat-select style="color: red" id="usertype" formControlName="userType" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let u of  userTypeList" [value]="u.userTypeId">
                {{u.userTypeName}}
              </mat-option>
            </mat-select>
            <mat-error style="color: rgb(0, 0, 0);" *ngIf=" f.userType.hasError('required')">
              Please Select <strong>userType</strong>
            </mat-error>
          </mat-form-field> -->






          <div align="center">
            <button mat-stroked-button color="primary" style="width: 30%; margin: 20px 0 0 8px;" mat-button
              type="button" routerLink="/login">Cancel</button>
            <button mat-stroked-button color="warn" style="width: 30%; margin: 20px 0 0 8px;" mat-button
              type="submit">Register</button>
          </div>

        </div>
      </form>
    </ng-template>
  </div>
</div>
