import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  UserForm!: FormGroup
  hide = true;
  loading = false;
  freetriallist : any = [];
  constructor(private fb: FormBuilder , private gs : GlobalserviceService , private router: Router) { }

  ngOnInit(): void {
     
    this.UserForm = this.fb.group({
    
            name: ['', Validators.required],
           email: ['',[ Validators.required , Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],   
           phoneNumber: ['', [Validators.required,Validators.minLength(10)]],   
          password : ['', Validators.required] ,
          freetrial :  this.fb.group({
            freetrialId : ['', Validators.required] , 
          })
        
      
    });
    this.gs.get_freetrial().subscribe((a: any) => {
      this.freetriallist = a;

      
})

  }
  
  get f() {
    return this.UserForm.controls;
  }

  createuser()
  {
    console.log(this.UserForm.value)
    let x = this.UserForm.value;
    

    if (this.UserForm.invalid ) {
      return
    }

    let final_data = {
      name:x.name,
      email:x.email,
      phoneNumber:x.phoneNumber,
      password: x.password ,
      freeTrial : {
        freeTrialId : x.freetrial.freetrialId
      }
      
    }

    console.log(JSON.stringify(final_data))
    this.loading = true
    this.gs.adduser(final_data).subscribe(
      (d: any) => {
        console.log(d)
        this.loading = false
        this.router.navigate(['/user-induvar']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }

  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
