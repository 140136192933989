import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { LiveTrackComponent } from '../../live-track/live-track.component';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-manage-user-induvar',
  templateUrl: './manage-user-induvar.component.html',
  styleUrls: ['./manage-user-induvar.component.scss']
})
export class ManageUserInduvarComponent implements OnInit {

  displayedColumns: string[] = ['userId', 'name', 'email', 'phoneNumber', 'userType', 'Action'];
  dataSource = new MatTableDataSource;
  isOrg: boolean = false

  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;

  constructor(public dialog: MatDialog, private gs: UserService,
    private Gs: GlobalserviceService, private auth: AuthService) {
  }

  ngOnInit() {
    console.log(this.gs.userList)
    this.Gs.get_user_induvar().subscribe(
      (d: any) => {
        this.dataSource = new MatTableDataSource(d)
        this.dataSource.paginator = this.paginator;
      }
    )

  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
