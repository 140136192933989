<div class="login_container">
  <div class="col-md-4">
    <ng-container *ngIf="loading; else elseComponent">
      <div class="loader">
        <app-loading></app-loading>
        <br>
        <h2>Please Wait... Verifying</h2>
      </div>
    </ng-container>

    <ng-template #elseComponent>
      <div class="brand">
        <img src="/assets/jepericon.png" alt="" height="75px" />
        <h4>Verify</h4>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div>
          <h3 style="
          color: #3e4244;
          font-size: larger;
          font-weight: 500;
      ">We've sent email with your code to {{id}}</h3>
        </div>
        <div class="form-group">
          <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Enter verification code</mat-label>
            <mat-icon matPrefix>vpn_key</mat-icon>
            <input id="password1" type="text" formControlName="OTP" [errorStateMatcher]="matcher"
              placeholder="******" [ngClass]="{ 'is-invalid': submitted && f.OTP.errors }" matInput>
            <mat-error style="color: black;" *ngIf=" submitted && f.OTP.hasError('required')">
              Please enter <strong>Passcode</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <button style="width: 100%;" mat-stroked-button>VERIFY</button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
