<ng-container *ngIf="isLoading; else elseTemplate">
  <div class="loading">
    <app-loading></app-loading>
    <br>
    <h1>Please Wait Loading...</h1>
  </div>
</ng-container>

<ng-template #elseTemplate>
    <div class="container">
  
      <div class="mat-elevation-z8">
    
        <div class="header_action">
          <!-- <button mat-flat-button>
            <span class="material-icons">
              location_on
            </span>
            <span>
              Assign Location
            </span>
          </button> -->
          <button mat-flat-button   [routerLink]="['/add-location']" >
            <span class="material-icons">
              add
            </span>
            <span>Create Location</span>
          </button>
  
         
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="search latitude/ longitude/ address" #input>
          </mat-form-field>
        </div>
        <!-- <div class="filter">
    
        </div> -->
        <table mat-table [dataSource]="dataSource">
    
          <!-- Position Column -->
          <ng-container matColumnDef="locationId">
            <th mat-header-cell *matHeaderCellDef> <b>Sl.No</b> </th>
            <td mat-cell *matCellDef="let element; let i =index"> {{ currentPageOffset + i + 1 }}</td>
          </ng-container>
    
          <!-- Name Column -->
          <ng-container matColumnDef="latitude">
            <th mat-header-cell *matHeaderCellDef> <b>LATITUDE</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
          </ng-container>
    
    
    
          <!-- Weight Column -->
          <ng-container matColumnDef="longitude">
            <th mat-header-cell *matHeaderCellDef> <b>LONGITUDE</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
          </ng-container>
    
          <!-- Symbol Column -->
          <!-- <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
          </ng-container> -->
         
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <div class="side_setting">

                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <a  mat-menu-item  (click)="editLocation(element)" > <span class="material-icons v"
                    style="    transform: translateY(7px);font-size: 22px;margin-right:3px">edit</span> Edit 
                    Location</a>
                  <a  mat-menu-item  (click)="viewaddress(element)" >
                    <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>               
                </mat-menu>
              </div>

           
              
              
             
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4" align="center">
              <div class="alert alert-secondary" style="width: 128%;">
                <h3>No Location List Found {{input.value}}</h3>
              </div>
            <td>
          </tr>
        </table>
    
    
        <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
    
      </div>
    </div>
    </ng-template>
  
  