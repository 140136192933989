import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  isOrg: boolean = false

  userTypeList: any[] = []


  userForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private gs: GlobalserviceService) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      password: ['', Validators.required],
      userType: ['', Validators.required],
    })

    if (this.auth.getLocal_Org()?.organizationId) {
      this.isOrg = true;
    } else {
      console.log("else");
      this.isOrg = false
    }

    this.gs.get_usertype().subscribe(a => {
      console.log(a);
      let userTypeId = this.auth.get_local_userType()

      if (userTypeId == 1) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4
        })
        console.log("primary_user1",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList1",this.userTypeList);
      }

      if (userTypeId == 2) {
        let primary_user: any = a.filter(u => {
          return u.userTypeId !== 1 && u.userTypeId !== 4 && u.userTypeId !== 2
        })
        console.log("primary_user2",primary_user);
        this.userTypeList = primary_user;
        console.log("this.userTypeList2",this.userTypeList);
      }

      // if (a.length > 0) {
      //   const data = a.filter(u => {
      //     return u.userTypeId !== 1 && u.userTypeId !== 4
      //   })
      //   console.log(data)
      //   this.userTypeList = data
      // }

    })

  }


  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() {
    return this.userForm.controls;
  }

  create_user() {
    let x = this.userForm.value
    console.log(x)

    let org = this.auth.getLocal_Org()
    let user = this.auth.getLocalStorageData()

    console.warn(this.isOrg)

    if (this.isOrg) {
      let z = {
        name: x.name,
        phoneNumber: x.phoneNumber,
        email: x.email,
        password: x.password,
        authenticationType: "null",
        createdByUser: user.userId,
        address: org.address,
        postalCode: org.postalCode,
        alternatePhoneNumber: x.phoneNumber,
        organization: {
          organizationId: org.organizationId
        },

        userType: [
          {
            userTypeId: x.userType
          }
        ],
        // states: {
        //   stateId: org.state.stateId
        // },
        // country: {
        //   countryId: org.country.countryId
        // },
        // city: {
        //   cityId: org.city.cityId
        // }
      }
      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Create_Org_User(z).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          'User Created Successfully',
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )
    } else {
      let z = {
        name: x.name,
        phoneNumber: x.phoneNumber,
        email: x.email,
        password: x.password,
        authenticationType: "null",
        createdByUser: user.userId,
        // country: {
        //   countryId: user.country.countryId
        // },
        // states: {
        //   stateId: user.states.stateId
        // },
        // city: {
        //   cityId: user.city.cityId
        // },
        userType: [
          {
            userTypeId: x.userType
          }
        ],
        postalCode: user.postalCode,
        address: user.address,
      }

      console.log("user with individual", z)
      console.log(JSON.stringify(z))
      this.isLoading = true
      this.gs.Create_Org_User(z).subscribe((a: any) => {
        this.isLoading = false
        this.dialog.closeAll()
        Swal.fire(
          "User Created Successfully",
          "",
          'success'
        )
        console.log(a)
      },
        err => {
          console.log
          this.isLoading = false
          this.dialog.closeAll()
          Swal.fire(
            err.error.message,
            "",
            'error'
          )
        }
      )

    }
  }
}
