import { Component, OnInit,HostListener,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
 import { AgmDrawingManager } from '@agm/drawing/lib/directives';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
declare var google: any;
import Swal from 'sweetalert2';
import { Router,ActivatedRoute} from '@angular/router';
import { LatLngLiteral } from '@agm/core';
import { AgmPolygon, PolygonManager , MapsAPILoader } from '@agm/core';
import { AuthService } from 'src/app/Authenticaton/auth.service';
// import { AgmDrawingManager } from '@agm/drawing';



@Component({
  selector: 'app-geofence',
  templateUrl: './geofence.component.html',
  styleUrls: ['./geofence.component.scss']
})
export class GeofenceComponent implements OnInit {
  // @ViewChild(AgmDrawingManager) drawingManager: AgmDrawingManager;
  geofenceForm:FormGroup;
  submitted:Boolean=false;
  geofenceLoc:Boolean=false;
  latitude: any;
  longitude: any;
  zoom:any;
  latlondData:any;
  polygon: any;
  isLoading :any;
  geofenaceId: any;
  geofenceData: any;
  mapData:any = [];
  latlongData: any=[];
  loc:any=[];
  localUserId: any;
  latdata: string[];
  managerOptions :any = {};
  mapready: boolean = false;
  

  constructor(private fb: FormBuilder,public ActivatedRoute: ActivatedRoute, private router: Router,private gs : GlobalserviceService
    , public mapsApiLoader: MapsAPILoader ,private auth: AuthService) 
    { 

      this.mapsApiLoader.load().then(() => { 
         this.managerOptions = {
          drawingControl: true,
          drawingControlOptions: {
            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            draggable: true,
            editable: true
          },
          drawingMode: google.maps.drawing.OverlayType.POLYGON
        };


      });
    }

  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata.userId);
    this.localUserId=userdata.userId;
    console.log(this.localUserId);
    this.geofenaceId= this.ActivatedRoute.snapshot.params.geofenaceId;
    console.log(this.geofenaceId);
    this.isLoading = false;
    this.latitude=12.74099;
    this.longitude=77.8253;
    this.zoom = 15;

    this.geofenceForm = this.fb.group({
      geofencename:['',[Validators.required]],
      geofencelocation:['']
    })
    console.log("older polygon",this.latlondData);
  // Patch value
    if(this.geofenaceId){
      this.gs.getGeofenceById(this.geofenaceId).subscribe(
        (data:any)=>{
          this.geofenceData=data;
      //    console.log(this.geofenceData);
       //   console.log(this.geofenceData.geofenacePoints);
          this.latlondData=this.geofenceData.geofenacePoints;
       //   console.log(this.latlondData);
          // this.polygonCreated(event)
         this.geofenceForm.patchValue({
          geofencename:this.geofenceData.geofenaceName,
          geofencelocation:this.geofenceData.geofenacePoints
        })
        console.log(this.geofenceForm);
        console.log(this.geofenceForm.value.geofencelocation);
        this.loc=this.geofenceForm.value.geofencelocation;
        console.log(this.loc);
        console.log("new polygon",this.latlondData);
        //  const data1 = this.geofenceForm.value.geofencelocation;
        //  console.log(data1);
        // const pairs = data1.replace(/[()]/g, '').split(')');
        // const coordinates = pairs.map((pair: string)=> {
        //   const [lat, lng] = pair.split(',');
        //   return { lat: parseFloat(lat), lng: parseFloat(lng) };
        // });
        if(this.geofenceForm.value.geofencelocation!=null)
        {
          const data1 = this.geofenceForm.value.geofencelocation;       
          data1.split(')(').forEach((item:any ,  i:any ) => {
            const coordinates = item.replace('(', '').replace(')', '').split(',');
            if(i == 0)
            {
               this.latitude =  parseFloat(coordinates[0]);
               this.longitude = parseFloat(coordinates[1]);
            }
            const lat = parseFloat(coordinates[0]);
            const lng = parseFloat(coordinates[1]);
             
            const obj = { lat, lng };
            this.latlongData.push(obj);
            console.log(this.latlongData);
          });
          this.onMapReady(this.map)
        }
      })
    }
  }

  map: google.maps.Map

  onMapReady(map: google.maps.Map) {
    this.map = map
     this.mapready = true;
    this.drawPolygon()
  }

  drawPolygon() {
    // const paths = [
    //     { lat: 12.3175489124641, lng: 78.48798591874993 },
    //     { lat: 8.210490392434776, lng: 77.38935310624993 },
    //     { lat: 10.59482777210473, lng: 79.58661873124993 },
    //   ]
    const paths=this.latlongData;
    console.log(paths)
     this.polygon = new google.maps.Polygon({
      paths: paths,
      editable: true,
      draggable: true,
    })
    this.polygon.setMap(this.map)
  }


  polygonCreated(event :any) {
    // console.log(event.getPaths().getArray()[0]);
    const vertices =event.getPaths().getArray()[0];
    console.log(vertices);
  //  vertices.getArray().forEach(function(xy:any, i:any) {
  //   let latLng = {
  //     lat: xy.lat(),
  //     lng: xy.lng()
  //   };
  //    console.log(latLng);
  // });
  this.mapData.length=0;
  console.log(this.mapData);
  let polumapdata: string | null;
   const _this = this;
   if(vertices.length!=0)
   {
    console.log("Lat Long Value");
    this.geofenceLoc=false;
  vertices.getArray().forEach(function(xy:any, i:any) {
    console.log(polumapdata);
      if(polumapdata==undefined)
      {
        polumapdata="(" + xy.lat() + "," + xy.lng()+")";
        console.log("loop1",polumapdata);
      }
      else if(polumapdata!=undefined)
      {
         let daa="(" + xy.lat() + "," + xy.lng()+")";
         polumapdata=polumapdata + daa;
         console.log("loop2",polumapdata);
      }
      _this.latlondData=polumapdata;
  });
      console.log(this.latlondData);
}
 else
 {
    console.log("Lat and Long zero");
    this.geofenceLoc=true;
 }
}
 
  GeofenceSubmit()
  {
    console.log(this.latlondData);
     if(this.latlondData==undefined)
     {
      console.log("Lat and Long zero");
      this.geofenceLoc=true;
     }
     else
     {
      console.log("Lat and Long Value");
      this.geofenceLoc=false;
     }
     this.submitted=true;
    //  this.geofenceLoc=true;
    console.log("submit");
    console.log(this.latlondData);
    if (this.geofenceForm.invalid || this.latlondData==undefined) {
      console.log("Lat Return Invaild");
      return;
    }
    if(this.geofenaceId)
    {
      console.log(this.geofenceForm.value);
      console.log("update");
      
      let data = this.polygon.getPaths().getArray()[0];
      let polumapdata :any;
      data.getArray().forEach(function(xy:any, i:any) {
        console.log(polumapdata);
          if(polumapdata==undefined)
          {
            polumapdata="(" + xy.lat() + "," + xy.lng()+")";
            console.log("loop1",polumapdata);
          }
          else if(polumapdata!=undefined)
          {
             let daa="(" + xy.lat() + "," + xy.lng()+")";
             polumapdata=polumapdata + daa;
             console.log("loop2",polumapdata);
          }
          
      });
      
       
      var updateData={
        geofenaceId:this.geofenaceId,
        geofenaceName:this.geofenceForm.value.geofencename,
        geofenacePoints:polumapdata,
        geofeanceStatus:
        {
            statusId:1
        },
        user:{
          userId:this.localUserId
          }

      }
      console.log("data",updateData);
      this.gs.updateGeofence(1,updateData).subscribe((d:any)=>{
        console.log(d);
        if(d.statusCode == 200){
          this.submitted=false;
          Swal.fire({
            text:d.description,
            icon:'success',
            timer: 2000,
          })
          setTimeout(() => {
             this.router.navigateByUrl('/manage-geofence');
          }, 2000)
        }
        if(d.statusCode == 409){
          Swal.fire({
            text:d.description,
            icon:'error',
            timer: 2000
          })
          setTimeout(() => {
          }, 2000)
        }
      },
      err=>{
        console.log("Error");
        Swal.fire({
          text:err.error.description,
          icon:'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
       );
    }
    else
    {
      console.log(this.geofenceForm.value);
      console.log(this.latlondData)

      var data={
          geofenaceName:this.geofenceForm.value.geofencename,
          geofenacePoints:this.latlondData,
          geofeanceStatus:
          {
              statusId:1
          },
          user:{
            userId:this.localUserId
            }
      }
      console.log("data",data);
      this.gs.createGeofence(data).subscribe((d:any)=>{
          console.log(d);
          if(d.statusCode == 200){
            this.submitted=false;
            Swal.fire({
              text:d.description,
              icon:'success',
              timer: 2000,
            })
            setTimeout(() => {
               this.router.navigateByUrl('/manage-geofence');
            }, 2000)
          }
          if(d.statusCode == 409){
            Swal.fire({
              text:d.description,
              icon:'error',
              timer: 2000
            })
            setTimeout(() => {
            }, 2000)
          }
        },
        err=>{
          console.log("Error");
          Swal.fire({
            text:err.error.description,
            icon:'error',
            timer: 2000
          })
          setTimeout(() => {
          }, 2000)
        }
         );
    }
  }
  
  get geofence() {
    return this.geofenceForm.controls;
  }
  
  // clearPolygon() {
  //   this.drawingManager['drawingManager'].setDrawingMode(null);
  // }
  
}
