import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './Authenticaton/forget-password/forget-password.component';
import { GuardGuard } from './Authenticaton/guard.guard';
import { LoginComponent } from './Authenticaton/login/login.component';
import { RegisterUserComponent } from './Authenticaton/register-user/register-user.component';
import { SignUpComponent } from './Authenticaton/sign-up/sign-up.component';
import { VerifyEmailComponent } from './Authenticaton/verify-email/verify-email.component';
import { VerifyOtpComponent } from './Authenticaton/verify-otp/verify-otp.component';
import { LiveTrackUserListComponent } from './Components/live-track-user-list/live-track-user-list.component';
import { AssignLocationComponent } from './Components/Location/assign-location/assign-location.component';
import { ManageUserInduvarComponent } from './Components/Organization/manage-user-induvar/manage-user-induvar.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { EditOrganziationComponent } from './Components/Organization/edit-organziation/edit-organziation.component';
import { OrganziationListComponent } from './Components/Organization/organziation-list/organziation-list.component';
import { OrganziationRegisterComponent } from './Components/Organization/organziation-register/organziation-register.component';
import { UserRegisterComponent } from './Components/Organization/user-register/user-register.component';
import { TestFormComponent } from './Components/test-form/test-form.component';
import { TrackUserListComponent } from './Components/track-user-list/track-user-list.component';
import { UserListComponent } from './Components/user/user-list/user-list.component';
import { ViewTrackReportComponent } from './Components/view-track-report/view-track-report.component';
import { LayoutComponent } from './Main-Layout/layout/layout.component';
import { LocationComponent } from './Components/Location/location/location.component';
import { AddLocationComponent } from './Components/Location/add-location/add-location.component';
import { UpdateLocationComponent } from './Components/Location/update-location/update-location.component';
import { ManageFreeTrialComponent } from './Components/manage-free-trial/manage-free-trial.component';
import { TrackComponent } from './Components/track/track.component';
import { SettingviewComponent } from './Components/settingview/settingview.component';
import { ManageGeofenceComponent} from './Components/manage-geofence/manage-geofence.component';
import { GeofenceComponent} from './Components/geofence/geofence.component';
import { AgmgeofenaceComponent } from './agmgeofenace/agmgeofenace.component';
import { AssignGeofenceComponent } from './Components/assign-geofence/assign-geofence.component';
import {CreateGeofenceComponent } from './Components/create-geofence/create-geofence.component'
const routes: Routes = [
  {
    path: '', component: LayoutComponent, canActivate: [GuardGuard], children: [
      { path: '', redirectTo: 'user', pathMatch: 'full' },
      { path: 'user', component: UserListComponent },
      { path: 'location', component: AssignLocationComponent },
      { path: "form", component: TestFormComponent },
      { path: "track-report/:id", component: ViewTrackReportComponent },
      {path: 'notification', component: NotificationComponent},
      {path: 'track-list', component: TrackUserListComponent},
      {path: 'org-list', component: OrganziationListComponent},
      {path: 'add-org', component: OrganziationRegisterComponent},
      {path: 'edit-org', component: EditOrganziationComponent},
      {path: 'add-user', component: UserRegisterComponent},
      { path: 'user-induvar',component:ManageUserInduvarComponent},
      {path: 'live-track-list', component: LiveTrackUserListComponent},
      {path: 'location-list', component: LocationComponent},
      {path: 'add-location', component: AddLocationComponent},
      {path : 'edit-location/:id' , component : UpdateLocationComponent},
      {path:'dont-track', component:TrackComponent},
      {path:'free-trial',component:ManageFreeTrialComponent} ,
      {path : 'dont-track-detail/:id' , component : SettingviewComponent},
      {path : 'manage-geofence' , component : ManageGeofenceComponent},
      {path : 'geofence' , component : GeofenceComponent},
      {path : 'geofence/:geofenaceId' , component : GeofenceComponent},
      {path : 'assign-geofence' , component : AssignGeofenceComponent},
      {path : 'agmgeofence' , component : AgmgeofenaceComponent},
      {path:'assign',component:CreateGeofenceComponent}
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'register', component: RegisterUserComponent },
  { path: 'verify-otp/:id', component: VerifyOtpComponent },
  { path: 'verify-email/:id', component: VerifyEmailComponent },
  {path: 'forget-password', component: ForgetPasswordComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
