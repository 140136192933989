// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDk91h4lCqKG8ULKfbFdgL33pC8Ehr9vuk",
    authDomain: "printlokapps.firebaseapp.com",
    databaseURL: "https://printlokapps-default-rtdb.firebaseio.com",
    projectId: "printlokapps",
    storageBucket: "printlokapps.appspot.com",
    messagingSenderId: "912507482410",
    appId: "1:912507482410:web:1e73d379bfddb988c7ee53",
    measurementId: "G-4VNSWZD7MM"
  }
 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
