
import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-view-track-report',
  templateUrl: './view-track-report.component.html',
  styleUrls: ['./view-track-report.component.scss']
})
export class ViewTrackReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['id', 'date', 'time',  'latitude', 'longitude',  'address'];
  dataSource = new MatTableDataSource;

  matcher = new MyErrorStateMatcher();
  showLogsForm!: FormGroup;
  endDate_disable: boolean = true;
  min: any;
  id: any
  workplacelist :any;

  user_data: any = {}

  constructor(private fb: FormBuilder,
    private gs: GlobalserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private datapipe : DatePipe
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.showLogsForm = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
    })

    this.route.paramMap.subscribe((a: any) => {
      let id = a.get('id')
      this.id = id
      this.gs.getUser_by_Id(id).subscribe(d => {
        console.log(d)
        this.user_data = d
      })
      let x: any = this.auth.getLocalStorageData()
      let today = this.datapipe.transform(Date() , 'yyyy-MM-dd');
            
      this.gs.get_current_workplace(today, id).subscribe((a: any) => {
        console.log(a)
      //  this.dataSource = a
      this.workplacelist =a;
      }, err => {

        Swal.fire(
          err.error.message,
          '',
          'error'
        )
        this.router.navigate(['/track-list'])
      })


    })

    console.log(this.id)
  }

  get f() {
    return this.showLogsForm.controls
  }


  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false
  }

  show() {
        
    alert("function called");
    let x = this.showLogsForm.value

       
    if (this.showLogsForm.invalid) {
      return
    }
  //  alert(this.showLogsForm.invalid);

    let formatfdate = this.datapipe.transform(x.fromDate , 'yyyy-MM-dd');
     let formattdate = this.datapipe.transform(x.toDate , 'yyyy-MM-dd');

     alert("formttdate" + formattdate);
    this.gs.get_report_byuser(formatfdate, formattdate , this.id).subscribe((a: any) => {
      console.log(a)
      this.dataSource =  new MatTableDataSource(a);
      this.dataSource.paginator = this.paginator;
    }, err => {

      Swal.fire(
        err.error.message,
        '',
        'error'
      )
   
    })
     
   
  }

  applyFilter(event: Event) {
    console.log(event)
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fromDate(a:any) {
    let x = new Date(a)
    return x

  }

  


}
