import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/Components/Location/assign-location/assign-location.component';
import Swal from 'sweetalert2';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  hide = true;
  email: boolean = true;
  /* form */
  loginForm!: FormGroup;

  loading = false;
  submitted = false;
  id: any

  public mask: any = [/[0-9]/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/]

  public isotpCount:boolean = true

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.email) {
      this.loginForm = this.formBuilder.group({
        OTP: ['', [Validators.required]],
      });
    } else {

    }

    this.route.paramMap.subscribe(a => {
      let x = a.get('id')
      console.log(x)
      this.id = x
    })
  }


  handleEvent(a: any) {
    console.log(a)
    this.isotpCount = false
  }

  resentOtp() {
    this.authService.Resend_Otp(this.id).subscribe(a => {
      this.isotpCount = true
      window.location.reload()
    }, err => {
      this.isotpCount = true
      this.loading = false
      console.log(err)
      let x = err.error.description
      if (x == undefined) {
        Swal.fire(
          "Check Net:: Connection :: err"
        )
      } else {
        console.log(err.error.description)
        if (err) {
          Swal.fire({
            text: err.error.description,
            icon: 'error'
          })
        }
      }
    },
      () => console.log("resend")
    )
  }

  get f() { return this.loginForm.controls; }

  change_field() {

    this.email = !this.email
    console.log(this.email)
  }

  onSubmit() {
    this.submitted = true;
    this.authService.verify_otp(this.f.OTP.value, this.id).subscribe(a => {
      console.log(a)
    })

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true
    // setTimeout(() => {
    //   this.loading = false
    //   this.router.navigate(['/user'])
    // }, 2000)
    let x = this.loginForm.value;

    console.log(x)
    this.authService.verify_otp(this.f.OTP.value, this.id).subscribe(
      d => {
        this.loading = false
        if (d) {
          Swal.fire({
            text: 'Verified Successfully',
            icon: 'success',
          })
        }
        this.router.navigate(['/']);
      },
      err => {
        this.loading = false
        console.log(err)
        let x = err.error.description
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.description)
          if (err) {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }
}
