<ng-container *ngIf="isLoading">
  <div class="loader">
    <app-loading></app-loading>
  </div>
</ng-container>
<mat-card class="col-md-8">
    <br>
    <mat-card-title>
      Edit Location
    </mat-card-title>
    <br>
    <form action="" [formGroup]="UpdateLocationForm" (ngSubmit)="editLocation()">

        <div>
          <div class="form-group">
            <label> Address</label>
            <input type="text"  id="address"  formControlName="AddressName" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Enter  Location"
              autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search (keyup)="getaddress()">
              <mat-error *ngIf="f.AddressName.hasError('required')">
                Please select <strong>Address</strong>
              </mat-error>
              <mat-error *ngIf="f.AddressName.hasError('pattern') ">
                Please enter a valid  address
              </mat-error>
          </div>
          <br>
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"  (mapClick)="mapClicked($event)">
            <agm-marker [latitude]="latitude" [longitude]="longitude" (dragEnd)="markerDragEnd($event)"
              [markerDraggable]="true"></agm-marker>
          </agm-map>
    
          <h5>Address: {{address}}</h5>
          <div>Latitude: {{latitude}}</div>
          <div>Longitude: {{longitude}}</div>
        </div>
    
        <div style="color: red;" *ngIf="f.address.invalid">
          Please select the location to track
        </div>
    
      
    
       
        <div>
          <button mat-stroked-button color="warn" style="width: 100%; margin: 20px 0 0 8px;" type="submit" mat-button>Edit Location</button>
        </div>
      </form>
</mat-card>