import { Component, OnInit ,ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-manage-geofence',
  templateUrl: './manage-geofence.component.html',
  styleUrls: ['./manage-geofence.component.scss']
})
export class ManageGeofenceComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'geofencename', 'status', 'action'];
  dataSource = new MatTableDataSource;
  userlist :any = [];
  isLoading: boolean = false
  @ViewChild(MatPaginator) paginator:any;
  dataList: any;
  nodata = false;
  currentPageOffset: number=0;
  userId: number;

  constructor(private Gs: GlobalserviceService,private auth: AuthService,private router : Router) { }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    console.log("userId",userdata.userId);
    this.userId=userdata.userId;
    console.log(this.userId);
    this.geofenceList(this.userId)
  }

  onPageChange(event: any) {
    this.currentPageOffset = event.pageIndex * event.pageSize;
  }
  
  openCreateGeofenceDialog()
  {
   console.log("Create");
  }
  applyFilter(event:any)
  {
    console.log("search");
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    console.log(this.dataSource.filter.length);
  }
  editLocation(geofenaceId:any){
    this.router.navigate(['geofence', geofenaceId])
  }
  geofenceList(userId:any)
  {
  this.Gs.get_Gefence_list(userId).subscribe((data: any) => {
    console.log(data);
    this.dataList=data.length;
    console.log(this.dataList);
    this.dataSource = new MatTableDataSource(data);
    console.log(this.dataSource);
    this.dataSource.paginator = this.paginator;
    if(data.length==0)
    {
      console.log("No Data");
      this.dataList.length=0;
      console.log(this.dataList);
      this.nodata = true;
      this.dataSource = new MatTableDataSource(this.dataList);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  },
  (error) =>{
    console.log("No data");
    if(error.error.errorCode==204){
      console.log("204 No Data",this.dataList);
      this.dataList.length=0;
      console.log(this.dataList);
      this.nodata = true;
      this.dataSource = new MatTableDataSource(this.dataList);
      console.log(this.dataSource);
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource);
    }
  }
  )
}

  updateStatus(id:any,status:any)
	{
    console.log("Status",id,status);
     this.Gs.updateGeofenceStatus(id,status).subscribe( (result:any) =>
		{
       if(result.statusCode == 200)
       {
        // this.submitted=false;
        Swal.fire({
          text:result.description,
          icon:'success',
          timer: 2000,
        })
        setTimeout(() => {
           this.router.navigateByUrl('/manage-geofence');
        }, 2000)
        this.geofenceList(this.userId);
       }
       if(result.statusCode == 409){
        Swal.fire({
          text:result.description,
          icon:'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
      },
       err=>{
        console.log("Error");
        Swal.fire({
          text:err.error.description,
          icon:'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
	 	)
	 }
}

