import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {

  countryList: any = []
  stateList: any = []
  cityList: any = []
  industrytypeList: any = []
  freetrialobj : any = [];
  selectedCountry_Code: any = "+"


  userRegisterForm!: FormGroup
  matcher = new MyErrorStateMatcher();
  hide = true;
  submitted = false;
  loading = false;

  userTypeList: any = []

  constructor(private fb: FormBuilder, private gs: GlobalserviceService, private router: Router) { }

  ngOnInit(): void {

    this.userRegisterForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      country: this.fb.group(
        {
          countryId: ['', Validators.required]
        }
      ),
      states: this.fb.group({
        stateId: ['', Validators.required],
      }),
      city: this.fb.group({
        cityId: ['', Validators.required]
      }),
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      password: ['', [Validators.required, Validators.minLength(8)]],
      authenticationType: ['', Validators.required],
    })


    this.gs.get_usertype().subscribe((a: any) => {
      console.log(a)
      this.userTypeList = a
    })

    this.getCountryList()
    this.getfreetrail()
  }

  private getCountryList() {
    this.gs.getCountry().subscribe((a: any) => {
      console.log(a)
      this.countryList = a;
    })
  }

  private getfreetrail()
  {
    this.gs.get_freetrial_default().subscribe(a => {
      console.log(a)
      this.freetrialobj = a
      console.log("free trail");
       console.log(this.freetrialobj);
    })
  }

  getState(a: any) {
    console.log(a)
    this.selectedCountry_Code = a.phoneCode
    this.gs.getState(a.countryId).subscribe(
      (d: any) => {
        this.stateList = d
      }, err => {
        console.log("state err", err)
      }
    )
  }

  getCity(a: any) {
    console.log("stateId", a)
    this.gs.getCity(a).subscribe(d => {
      console.log(d)
      this.cityList = d
    })
  }


  get f() {
    return this.userRegisterForm.controls
  }

  omit_special_char(event: { keyCode: any; charCode: any; }) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(JSON.stringify(this.userRegisterForm.value))
    if (this.userRegisterForm.invalid) {
      return;
    }

    this.loading = true

    let final_data;
     let x = this.userRegisterForm.value;
    if(this.freetrialobj[0].freeTrialId != null || this.freetrialobj[0].freeTrialId != undefined)
    {
       final_data = {
        ...x,
        freeTrial : {
          freeTrialId : this.freetrialobj[0].freeTrialId
        }
      }
    }else
    {
      final_data = {
        ...x
       
      }
    }
       console.log("User Registration" + JSON.stringify(final_data))

    this.gs.Create_Org_User(final_data).subscribe((a: any) => {
      console.log(a)

      this.router
      this.loading = false
      switch (this.f.authenticationType.value) {
        case 'SMS':
          Swal.fire(
            'Verify OTP',
            '',
            'success'
          )
          this.router.navigate(['/verify-otp', a.data?.phoneNumber]);
          break;
        case 'EMAIL':
          Swal.fire(
            'Please Verify Your Email',
            '',
            'success'
          )
          this.router.navigate(['/verify-email', a.data?.email]);
          break;
        default:
          break;
      }
    }, err => {
      console.log(err)
      Swal.fire(
        err.error.message,
        '',
        'error'
      )
      this.loading = false
    })

  }
}
